import {
  ArrowTopRightOnSquareIcon,
  UsersIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
  ClockIcon,
  HandRaisedIcon,
  CakeIcon,
  MusicalNoteIcon,
  FaceSmileIcon,
  SpeakerXMarkIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  WrenchScrewdriverIcon,
  DevicePhoneMobileIcon,
  InboxStackIcon,
  ReceiptRefundIcon,
  Cog8ToothIcon,
  UserGroupIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
// import { HandRaisedIcon } from "@heroicons/react/20/solid";
import {
  ExistingFormInputs,
  ParticipantOptionMenu,
  ParticipantsProps,
} from "../../interfaces/componentsInterface/participants";
import { getInitials } from "../../utils/initials";
import { Fragment, useEffect, useRef, useState } from "react";
import routes from "../../constants/routes";
import SkeletonLoading from "../SkeletonLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { mediaSelector, updateOnlineUsersInMedia } from "../../features/media/mediaSlice";
import { RemotePeerData } from "../../interfaces/componentsInterface/mediaInterface";
import SocketService from "../../services/socket";
import { roomViewModeConst, BRB_TIME_LIMIT_MINUTES, BREAK_TIME_LIMIT_MINUTES, emojiEventConst, LUNCH_TIME_LIMIT_MINUTES, socketConnectiontype, AGENT_TO_AGENT_PRIVATE_CALL, REMOVE_HANDRAISE_OF_OTHERS, REACT_APP_HEADPOSE_SECONDS } from "../../utils/constant";
import { roomSelector } from "../../features/room/roomSlice";
import SignalIndicator from "../SignalIndicator";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { constantMethod } from "../../utils/constantMethod";
import {
  privateCallSelector,
  setCallModal,
  setCaller,
} from "../../features/privateCall/privateCallSlice";
import toast from "react-hot-toast";
import { observeSelector } from "../../features/observe/observeSlice";
import { environmentSelector } from "../../features/environment/environmentSlice";
import {
  clearTempRoomUser,
  setTempRoomUser,
  tempRoomUserSelector,
} from "../../features/tempRoomUser/tempRoomUserSlice";
import { BreakParticipant } from "../../interfaces/slicesInterface/tempRoomUser";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../Input";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BreakoutFormInputs } from "../../interfaces/componentsInterface/participants";
import Button from "../Button";
import { createTempRoom, shiftToExistingRoom } from "../../services/room";
import { peerVolumeSelector } from "../../features/peerVolume/peerVolumeSlice";
import { getVolumeClasses } from "../../utils/audio";
import { chatSelector, setCurrentEmoji, setEventOther, setOnlineUsers } from "../../features/chat/chatSlice";
import { getFormattedMinutes, timeToMinutes } from "../../utils/datetimeformat";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { personalizationSelector } from "../../features/personalization/personalization";
import { notifyMe } from "../../utils/desktopNotification";
import { addEvent, updateEvent } from "../../features/alerts/alertsSlice";
import { EventType } from "../../interfaces/slicesInterface/alerts";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "../../app/store";
import handleClickOutside from "../../utils/click";
import { playChatNotification } from "../../utils/AudioVideoDevices";
import headIcon from "../../assets/images/headDown.svg"

const mediaSocketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const socketInstanceChat = new SocketService(
  socketConnectiontype.chat
).getInstance(socketConnectiontype.chat);

export default function Participants({
  active,
  setActive,
  setIsAlertActive,
  setIsParticipantActive,
  makePrivateCall,
  isParticipantActive,
  performRightShiftByOne,
  inPopup
}: ParticipantsProps): ReturnType<React.FC> {
  const mediaReducer = useAppSelector(mediaSelector);
  const [userIdForMenu, setUserIdForMenu] = useState("");
  const [open, setOpen] = useState(false);
  const [handRaised] = useState(false);
  const [tempRoomType, setTempRoomType] = useState("");
  const roomReducer = useAppSelector(roomSelector);
  const { isPrivateCallOn, callModalOption, openentCallerUserId } =
    useAppSelector(privateCallSelector);
  const { openentObserveeAudioId, openentObserverAudioId } =
    useAppSelector(observeSelector);
  const environments = useAppSelector(environmentSelector);
  const [imageError, setImageError] = useState(false);
  let participantsPopup = useRef<Window | null>(null);
  const [isParticipantsPopupOpened, setIsParticipantsPopupOpened] = useState(false);
  const spacesConfig =
    environments.environments &&
      environments.environments.REACT_APP_SPACES_CONFIG
      ? environments.environments.REACT_APP_SPACES_CONFIG.data[0]
      : "";
  const participantsRef = useRef(null)
  const environmentReducer = useAppSelector(environmentSelector);

  const tempRoomUser = useAppSelector(tempRoomUserSelector);
  const peerVolume = useAppSelector(peerVolumeSelector);
  const personalization = useAppSelector(personalizationSelector);
  const dispatch = useAppDispatch();
  var participantsWindow: any;
  let messageSubscriberChat: any;
  const chatReducer = useAppSelector(chatSelector);
  const userReducer = useAppSelector(userDetailsSelector);

  const lunchTimeLimitStatus = environments.environments
    .LUNCH_TIME_LIMIT_MINUTES
    ? environments.environments.LUNCH_TIME_LIMIT_MINUTES.status
    : LUNCH_TIME_LIMIT_MINUTES.status;

  const lunchTimeLimit = environments.environments.LUNCH_TIME_LIMIT_MINUTES
    ? environments.environments.LUNCH_TIME_LIMIT_MINUTES.value
    : LUNCH_TIME_LIMIT_MINUTES.value;

  const breakTimeLimitStatus = environments.environments
    .BREAK_TIME_LIMIT_MINUTES
    ? environments.environments.BREAK_TIME_LIMIT_MINUTES.status
    : BREAK_TIME_LIMIT_MINUTES.status;

  const breakTimeLimit = environments.environments.BREAK_TIME_LIMIT_MINUTES
    ? environments.environments.BREAK_TIME_LIMIT_MINUTES.value
    : BREAK_TIME_LIMIT_MINUTES.value;

  const brbTimeLimitStatus = environments.environments.BRB_TIME_LIMIT_MINUTES
    ? environments.environments.BRB_TIME_LIMIT_MINUTES.status
    : BRB_TIME_LIMIT_MINUTES.status;

  const brbTimeLimit = environments.environments.BRB_TIME_LIMIT_MINUTES
    ? environments.environments.BRB_TIME_LIMIT_MINUTES.value
    : BRB_TIME_LIMIT_MINUTES.value;

  const privateCallPermission = environments.environments.AGENT_TO_AGENT_PRIVATE_CALL
    ? environments.environments.AGENT_TO_AGENT_PRIVATE_CALL.status
    : AGENT_TO_AGENT_PRIVATE_CALL;

  const supervisorCanRemoveHandraise = environments.environments.REMOVE_HANDRAISE_OF_OTHERS
    ? environments.environments.REMOVE_HANDRAISE_OF_OTHERS.status
    : REMOVE_HANDRAISE_OF_OTHERS;

  const agentToagentStatus = environments.environments.AGENTTOAGENT ? environments.environments.AGENTTOAGENT.status : true;
  const headPoseSeconds = environmentReducer.environments.REACT_APP_HEADPOSE_SECONDS ? environmentReducer.environments.REACT_APP_HEADPOSE_SECONDS.value : REACT_APP_HEADPOSE_SECONDS;

  const [viewMode, setViewMode] = useState("");
  const [searchTerm, setSearchTerm] = useState('');


  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value.toLowerCase());
  };
  const {
    register: registerBreakoutForm,
    handleSubmit: handleBreakoutFormSubmit,
    setValue: setBreakoutValue,
    formState: { errors: breakoutFormErrors },
  } = useForm<BreakoutFormInputs>();
  const {
    register: registerExistingRoom,
    handleSubmit: handleExistingSubmit,
    setValue: setExistingValue,
    formState: { errors: existingErrors },
  } = useForm<ExistingFormInputs>();

  useEffect(() => {
    return () => {
      if (participantsWindow && !participantsWindow.closed) {
        participantsWindow.close();
      }
    };
  }, [participantsWindow]);

  const { firstName, lastName, username, userId, custId, role } =
    useAppSelector(userDetailsSelector);


  var participantsWindow: any;

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.action === "closeParticipantsPopup") {
        if (participantsPopup.current) {
          participantsPopup.current.close();
          setIsParticipantsPopupOpened(false);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const closeParticiantsModal = () => {
    setUserIdForMenu("");
  }

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => handleClickOutside(e, participantsRef, closeParticiantsModal);

    if (userIdForMenu !== "") {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [userIdForMenu]);

  const openParticipantsPopup = () => {
    let compiledTailwindCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/tailwind/tailwind.output.css`;
    if (!isParticipantsPopupOpened) {
      setIsParticipantsPopupOpened(true);

      localStorage.setItem("participantsPopOut", "true");
      //participantsPopup.current = window.open("", "_blank", "width=500,height=650");
      // Calculate the top and left positions based on the current screen's available width and height
      const topPosition = window.screenY + (window.innerHeight * 0.09);
      const leftPosition = window.screenX + (window.innerWidth * 0.45);

      // Open popup with responsive width, height, and calculated top/left positions
      participantsPopup.current = window.open("", "_blank", `width=500,height=650,top=${topPosition},left=${leftPosition}`);

      if (!participantsPopup.current) {
        console.error("Failed to open Alerts popup window.");
        return;
      }

      participantsPopup.current.document.open("text/html", "replace");

      participantsPopup.current.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <title>Participants - Collaborationroom.ai</title>
            <link rel="stylesheet" href="${compiledTailwindCssUrl}">
            <style>
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    scroll-behavior: smooth;
                    /* overflow: hidden; */
                }

                ::selection {
                    background-color: #4EA3DB;
                    color: #fff;
                }

                .scrollbar::-webkit-scrollbar {
                    width: 10px;
                }

                .scrollbar::-webkit-scrollbar-thumb {
                    background-color: #eaeaea;
                    border-radius: 10px;
                }

                .scrollbar::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }

                textarea::placeholder {
                    text-align: center;
                    padding: auto 0 !important;
                    display: table-cell;
                    vertical-align: middle !important;
                    margin-top: 10% !important;
                }

                textarea:focus {
                    outline: none;
                    max-height: 100%;
                    max-width: 100% !important;
                    overflow-y: scroll !important;
                    overflow-x: hidden;
                }

                .w-full__suggestions {
                    margin: 0 !important;
                    z-index: 1000;
                    margin-left: 2px;
                    top: 10% !important;
                    left: 10% !important;
                }

                .w-full__suggestions__list {
                    z-index: 10;
                    background: white;
                    border: solid 1px #4EA3DB;
                    background: #4EA3DB;
                    color: #fff;
                }

                .w-full__highlighter {
                    height: auto !important;
                }

                textarea::-webkit-scrollbar {
                    width: 10px;
                }

                textarea::-webkit-scrollbar-thumb {
                    background-color: #eaeaea;
                    border-radius: 10px;
                }

                textarea::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }

                .level_0 {
                  border: 0;
                }

                .level_1 {
                   border: solid 2px var(--volume);
                }

                .level_2 {
                  border: solid 4px var(--volume)
                }

                .level_3 {
                  border: solid 6px var(--volume);

                }

                .level_4 {
                  border: solid 8px var(--volume);
                }

              .level_5 {
                border: solid 10px var(--volume);
                }

                :root {
                    --blue: #4EA3DB;
                }
            </style>
            <script>
                window.addEventListener('storage', (event) => {
                    if (event.key === 'participantsPopOut') {
                        const isParticipantsPopOut = JSON.parse(event.newValue); // Parse the new value from storage
                        if (!isParticipantsPopOut) {
                            window.close();
                        }
                    }
                });

                window.addEventListener('beforeunload', () => {
                    if (window.opener) {
                        window.opener.postMessage({ action: "closeParticipantsPopup" }, "*");
                    }
                });
            </script>
        </head>
        <body>
            <div id="root"></div>
        </body>
        </html>
    `);

      participantsPopup.current.document.close();

      participantsPopup.current.onload = () => {
        const rootElement = participantsPopup.current?.document.getElementById("root");

        if (rootElement) {
          const root = createRoot(rootElement);
          root.render(
            <Provider store={store}>
              <div>
                <Participants
                  active={active}
                  setActive={setActive}
                  setIsAlertActive={setIsAlertActive}
                  setIsParticipantActive={setIsParticipantActive}
                  makePrivateCall={makePrivateCall}
                  isParticipantActive={isParticipantActive}
                  performRightShiftByOne={performRightShiftByOne}
                  inPopup={true}
                />
              </div>

            </Provider>
          );
        } else {
          console.error("Root element not found in the chat popup window.");
        }
      };

      const closePopup = () => {
        if (participantsPopup.current) {
          participantsPopup.current.close();
          setIsParticipantsPopupOpened(false);
        }
      };

      // Add event listener for beforeunload to close the popup on page reload or navigation
      window.addEventListener("beforeunload", closePopup);

      // Ensure the event listener is removed when the component is unmounted or popup is closed
      participantsPopup.current.onbeforeunload = () => {
        setIsParticipantsPopupOpened(false);
        window.removeEventListener("beforeunload", closePopup);

      };
    }
  };



  const handleParticipantsClose = () => {
    setIsAlertActive && setIsAlertActive({ val: false, count: 0 });
    setIsParticipantActive && setIsParticipantActive({ val: false, count: 0 });
    setActive && setActive(false);
  };

  const onBreakoutSubmit: SubmitHandler<BreakoutFormInputs> = async (data) => {
    const regex = /\w+\s+\w+/;
    if (regex.test(data.roomName)) {
      toast.error("Spaces are not allowed in roomname");
      return;
    }

    if (data.roomName.length > 20) {
      toast.error("Roomname cannot exceed 20 characters");
      return;
    }
    if (Number.isNaN(Number(data.duration))) {
      toast.error("Please Enter valid Duration in Numbers.");
      return;
    }

    if (Number(data.duration) < 1 || Number(data.duration) > 30) {
      toast.error("Duration Must be between 1 and 30");
      return;
    }

    const tempRoomBody = {
      roomName: data.roomName,
      supervisorId: data.supervisor,
      duration: data.duration,
      creator: userId,
      bussId: roomReducer.currentRoomBussId,
      usersList: tempRoomUser.breakParticipants,
      isTemp: true,
      parentRoom: roomReducer.currentRoom,
      // bussId:
    };

    try {
      const response = await createTempRoom(tempRoomBody);
      if (response.success) {
        toast.success("Breakout room has been created successfully");
        mediaSocketInstance.sendMessage("SHIFT_TO_ANOTHER_ROOM", {
          roomId: response.roomId,
          roomname: roomReducer.currentRoom + "." + data.roomName,
          viewMode: roomViewModeConst.lectureMode,
          userList: tempRoomUser.breakParticipants,
        });

        dispatch(clearTempRoomUser());
        setOpen(false);
      } else {
        toast.error(response.message);
      }
    } catch (err: unknown | any) {
      toast.error(err.message);
      console.log(err.message);
    }
  };

  const onExistingSubmit: SubmitHandler<ExistingFormInputs> = async (data) => {
    console.log({ data });

    const body = {
      roomId: data.room,
      usersList: tempRoomUser.breakParticipants,
    };

    const room = roomReducer.rooms.filter(
      (room: any) => room._id === data.room
    );
    try {
      const response = await shiftToExistingRoom(body);
      if (response.success) {
        toast.success(`Users are successfully shifted to ${room[0].roomName}`);
        mediaSocketInstance.sendMessage("SHIFT_TO_ANOTHER_ROOM", {
          roomId: data.room,
          roomname: room[0].roomName,
          viewMode: room[0].roomType,
          userList: tempRoomUser.breakParticipants,
        });

        dispatch(clearTempRoomUser());
        setOpen(false);
      } else {
        toast.error(response.message);
      }
    } catch (err: unknown | any) {
      toast.error(err.message);
      console.log(err.message);
    }
  };

  const handleRemoveHandraise = (peerUserId: string, peerUsername: string, peerName: string) => {
    // Action to perform when toggling OFF
    socketInstanceChat.sendMessage("EMOJI_OTHER_ACTIONS", {
      roomname: roomReducer.currentRoom,
      roomId: roomReducer.currentRoomId,
      type: "",
      userId: peerUserId,
      supHandRaiseRemoveUsername: peerName,
      supHandRaiseRemoveFlag: true,
    });
    toast.dismiss();
    toast.success(peerName + " Handraise removed by " + firstName + " " + lastName);
    notifyMe(peerName + " Handraise removed by " + firstName + " " + lastName);
  }

  const promoteUser = (peerUserId: string) => {
    mediaSocketInstance.sendMessage("PROMOTE_USER", {
      roomname: roomReducer.currentRoom,
      peerUserId,
    });
    setUserIdForMenu("");
  };

  const handleMenuUserId = (peerUserId: string) => {
    if (peerUserId === userIdForMenu) {
      setUserIdForMenu("");
    } else {
      setUserIdForMenu(peerUserId);
    }
  };
  const handlePrivateCall = (peerUser: RemotePeerData) => {
    if (
      (peerUser.isPrivateCallOn &&
        isPrivateCallOn &&
        openentCallerUserId === peerUser.peerUserId) ||
      (!peerUser.isPrivateCallOn && !isPrivateCallOn)
    ) {
      // if (mediaReducer.isScreenAudioShared) {
      if (false) {
        toast(
          "You are sharing your Screen with Audio. Please Stop screen or share screen without audio to make private call"
        );
        return;
      }
      if (
        ((!peerUser.isPrivateCallOn && !peerUser.isCallModalOn) ||
          openentCallerUserId === peerUser.peerUserId) &&
        makePrivateCall
      )
        makePrivateCall(peerUser.peerUserId);
    }
    setUserIdForMenu("")
  };

  const handleStartAndStopObserveAgent = (
    peerUserId: string,
    openentObserverAudioId?: string
  ) => {
    if (
      (openentObserveeAudioId !== "" &&
        openentObserveeAudioId !== peerUserId) ||
      (openentObserverAudioId !== "" && openentObserveeAudioId === "")
    ) {
      console.log("inside stop");
      return;
    }
    if (openentObserveeAudioId === peerUserId) {
      mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        userId: peerUserId,
        from: "stop",
      });
    } else {
      mediaSocketInstance.sendMessage("OBSERVE_AUDIO_AGENT", {
        roomname: roomReducer.currentRoom,
        userId: peerUserId,
      });
    }


    setUserIdForMenu("")
  };
  const muteAll = () => {
    mediaSocketInstance.sendMessage("MUTE_ALL_PARTICIPANT", {
      roomId: roomReducer.currentRoomId,
    });
  };

  useEffect(() => {
    console.log("peers", mediaReducer.remotePeers);
  }, [mediaReducer.remotePeers]);

  const getLatestPeerStatus = async () => {
    return await socketInstanceChat.sendMessage("GET_ONLINE_CHAT_USERS", {});
  };

  useEffect(() => {
    if (!inPopup) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      messageSubscriberChat = socketInstanceChat
        .getMessages()
        .subscribe((message: string) => {
          processMessage(JSON.parse(message));
        });
    }
    return () => {
      messageSubscriberChat.unsubscribe();
    };
  }, []);

  const handleRemoveParticipant = (
    peerUserId: string,
    peerUsername: string,
    peerName: string
  ) => {
    mediaSocketInstance.sendMessage("REMOVE_USER", {
      peerUserId,
      peerUsername: peerUsername,
      peerName,
    });
  };

  const processMessage = async (message: any) => {
    switch (message.type) {
      case "EMOJI_FOR_BREAKANDLUNCH":
        getLatestPeerStatus().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });


        if (constantMethod.checkHigherRole(userReducer.role) || agentToagentStatus) {
          if (message.emojiType === emojiEventConst.LUNCH && message.peerUserId !== userReducer.userId && message?.flag) {
            notifyMe(`${message.peerUsername} is on LUNCH`);
          }

          if (message.emojiType === emojiEventConst.BREAK && message.peerUserId !== userReducer.userId && message?.flag) {
            notifyMe(`${message.peerUsername} is on BREAK`);
          }
        }


        // Show time exceeded toast to higher roles
        if (
          constantMethod.checkHigherRole(userReducer.role) &&
          message.emojiType &&
          message.emojiType === emojiEventConst.LUNCH
        ) {
          if (
            lunchTimeLimitStatus &&
            timeToMinutes(message.time) > +lunchTimeLimit
          ) {
            toast(
              "Lunch time limit exceeded for user " +
              message.peerUsername +
              ". It was set to " +
              lunchTimeLimit +
              " minute."
            );
          }
        }

        if (
          constantMethod.checkHigherRole(userReducer.role) &&
          message.emojiType &&
          message.emojiType === emojiEventConst.BREAK
        ) {
          if (
            breakTimeLimitStatus &&
            timeToMinutes(message.time) > +breakTimeLimit
          ) {
            toast(
              "Break time limit exceeded for user " +
              message.peerUsername +
              ". It was set to " +
              breakTimeLimit +
              " minute."
            );
          }
        }
        if (message.flag === "endbreak") {
          dispatch(setCurrentEmoji(""))
        }
        if (message.flag === "endlunch") {
          dispatch(setCurrentEmoji(""))
        }

        if (constantMethod.checkHigherRole(userReducer.role)) {
          if (message.flag === "startbreak") {
            dispatch(addEvent({
              userId: message.peerUserId,
              event: EventType.Break,
              peerName: message.peerName,
              peerUsername: message.peerUsername,
              startTime: Date.now(),

            }))
          }

          if (message.flag === "endbreak") {
            dispatch(updateEvent({
              userId: message.peerUserId,
              event: EventType.Break,
              endTime: Date.now()
            }))
          }


          if (message.flag === "startlunch") {
            dispatch(addEvent({
              userId: message.peerUserId,
              event: EventType.Lunch,
              peerName: message.peerName,
              peerUsername: message.peerUsername,
              startTime: Date.now(),

            }))
          }

          if (message.flag === "endlunch") {
            dispatch(updateEvent({
              userId: message.peerUserId,
              event: EventType.Lunch,
              endTime: Date.now()
            }))
          }



        }
        break;
      case "EMOJI_FOR_MEETING":
        getLatestPeerStatus().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });

        if (constantMethod.checkHigherRole(userReducer.role) || agentToagentStatus) {
          if (message.emojiType === emojiEventConst.MEETING && message.peerUserId !== userReducer.userId && message?.flag) {
            notifyMe(`${message.peerUsername} is on MEETING`);
          }
        }

        if (message.flag === "endmeeting") {
          dispatch(setCurrentEmoji(""))
        }

        if (constantMethod.checkHigherRole(userReducer.role)) {
          if (message.flag === "startmeeting") {
            dispatch(addEvent({
              userId: message.peerUserId,
              event: EventType.Meeting,
              peerName: message.peerName,
              peerUsername: message.peerUsername,
              startTime: Date.now(),
            }))
          }

          if (message.flag === "endmeeting") {
            dispatch(updateEvent({
              userId: message.peerUserId,
              event: EventType.Meeting,
              endTime: Date.now()
            }))
          }
        }
        break;
      case "EMOJI_FOR_DND":
        getLatestPeerStatus().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });
        if (constantMethod.checkHigherRole(userReducer.role) || agentToagentStatus) {
          if (message.emojiType === emojiEventConst.DND && message.peerUserId !== userReducer.userId && message?.flag) {
            notifyMe(`${message.peerUsername} is on DND`);
          }
        }

        // if (message.flag === "enddnd") {
        //   dispatch(setCurrentEmoji(""))
        // }
        if (constantMethod.checkHigherRole(userReducer.role)) {
          if (message.flag === "startdnd") {
            dispatch(addEvent({
              userId: message.peerUserId,
              event: EventType.DND,
              peerName: message.peerName,
              peerUsername: message.peerUsername,
              startTime: Date.now(),

            }))
          }

          if (message.flag === "enddnd") {
            dispatch(updateEvent({
              userId: message.peerUserId,
              event: EventType.DND,
              endTime: Date.now()
            }))
          }
        }
        break;
      case "EMOJI_FOR_BRB":
        getLatestPeerStatus().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });


        if (constantMethod.checkHigherRole(userReducer.role) || agentToagentStatus) {
          if (message.emojiType === emojiEventConst.BRB && message.peerUserId !== userReducer.userId && message?.flag) {
            notifyMe(`${message.peerUsername} is on BRB`);
          }

        }

        if (constantMethod.checkHigherRole(userReducer.role) && message.emojiType && message.emojiType === emojiEventConst.BRB) {
          if (brbTimeLimitStatus && timeToMinutes(message.time) > +brbTimeLimit) {
            toast(
              "Be Right Back time limit exceeded for user " +
              message.peerUsername +
              ". It was set to " +
              brbTimeLimit +
              " minute."
            );
          }
        }

        if (message.flag === "endbrb") {
          console.log("something")
          dispatch(setCurrentEmoji(""))
        }

        if (constantMethod.checkHigherRole(userReducer.role)) {
          if (message.flag === "startbrb") {

            dispatch(addEvent({
              userId: message.peerUserId,
              event: EventType.BRB,
              peerName: message.peerName,
              peerUsername: message.peerUsername,
              startTime: Date.now(),
            }))
          }


          if (message.flag === "endbrb") {
            dispatch(updateEvent(
              {
                userId: message.peerUserId,
                event: EventType.BRB,
                endTime: Date.now()
              }
            ))
          }
        }
        break;

      case "EMOJI_OTHER_ACTIONS":
        getLatestPeerStatus().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });

        // console.log({messageDataThis : message});

        if (message.supHandRaiseRemoveUser === userReducer.userId) {
          if (message.supHandRaiseRemoveFlag) {
            toast.dismiss();
            toast.success(`Your handraise is removed by the ${message.peerName}`);
            notifyMe(`Your handraise is removed by the ${message.peerName}`);
          }
          dispatch(setEventOther(message.emojiType));
          dispatch(setCurrentEmoji(message.emojiType));
        }
        // console.log({message})
        // dispatch(updateRemotePeers(message.peerUserId))

        if (message.emojiType === emojiEventConst.HANDRAISE && (constantMethod.checkHigherRole(userReducer.role) || (!constantMethod.checkHigherRole(userReducer.role) && agentToagentStatus) || (!constantMethod.checkHigherRole(userReducer.role) && (constantMethod.checkHigherRole(message.peerType))))) {

          if (performRightShiftByOne && message.peerUserId) {
            performRightShiftByOne(message.peerUserId, true)
          }

          // dispatch(updateOnlineUsersInMedia({ userId: message.peerUserId, onlineUsersInChats: store.getState().chat.onlineUsers }))
        }


        if (message.emojiType === emojiEventConst.HANDRAISE && message.peerUserId !== userReducer.userId && constantMethod.checkHigherRole(userReducer.role)) {

          if (store.getState().personalization?.personalization?.settings?.handRaisedAndSupport?.audio) {
            playChatNotification();
          }

          // Show toast notification if needed
          if (store.getState().personalization?.personalization?.settings?.handRaisedAndSupport?.toast) {
            toast.dismiss();
            toast.success(`${message.peerName} Raised Hand!`)
          }

          // Show desktop notification if needed
          if (store.getState().personalization?.personalization?.settings?.handRaisedAndSupport?.desktop) {
            notifyMe(`${message.peerName} Raised Hand!`);
          }

          dispatch(addEvent({
            userId: message.userId,
            event: EventType.HandRaised,
            timestamp: Date.now(),
            startTime: Date.now(),
            peerName: message.peerName,
            peerUsername: message.peerUsername,
          }))

        }

        if (message.emojiType === emojiEventConst.TECHNICALISSUE && message.peerUserId !== userReducer.userId && constantMethod.checkHigherRole(userReducer.role)) {

          if (store.getState().personalization?.personalization?.settings?.handRaisedAndSupport?.audio) {
            playChatNotification();
          }

          // Show toast notification if needed
          if (store.getState().personalization?.personalization?.settings?.handRaisedAndSupport?.toast) {
            toast.dismiss();
            toast.success(`Support raised by ${message.peerName}`)
          }

          // Show desktop notification if needed
          if (store.getState().personalization?.personalization?.settings?.handRaisedAndSupport?.desktop) {
            notifyMe(`Support raised by ${message.peerName}`);
          }

          dispatch(addEvent({
            userId: message.userId,
            event: EventType.TechnicalIssue,
            timestamp: Date.now(),
            startTime: Date.now(),
            peerName: message.peerName,
            peerUsername: message.peerUsername,
          })) 

        }

        break;

      default:
        break;
    }
  };


  const filteredPeers = mediaReducer.remotePeersId.filter((id) => {
    const peer = mediaReducer.remotePeers[id];
    const peerName = peer.peerName?.toLowerCase() || '';
    const peerUsername = peer.peerUsername?.toLowerCase() || '';
    return peerName.includes(searchTerm) || peerUsername.includes(searchTerm);
  });


  return (
    <>
      <div
        className={`w-full ${active ? " h-[49%]" : "h-full"
          } bg-white  rounded-2xl sm:w-full  transition-all shadow-md duration-500 ${isParticipantActive && isParticipantActive.val ? "flex" : "hidden"} ${inPopup && "h-screen overflow-hidden"} flex justify-start items-start flex-col gap-2 relative`}
      >
        <div className=" w-full flex flex-col justify-start items-start p-2 h-[25%]">
          <div className=" w-full h-2/4 flex justify-between items-center ">
            <h1
              title="Participants"
              className=" font-sans text-gray-900 text-base font-semibold"
            >
              Participants
            </h1>
            {constantMethod.checkHigherRole(role) ? (
              <button
                type="button"
                // className="inline-flex items-center gap-x-1.5 rounded-md bg-blue px-5 py-1 text-xs font-normal text-white shadow-md hover:bg-indigo-500 focus-visible:outline border-[0.5px] border-transparent focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-5 py-1 text-sm font-medium shadow-md text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                onClick={muteAll}
              >
                Mute All
              </button>
            ) : null}


            {
              !inPopup &&
              <div className=" flex justify-center items-center gap-1">
                <ArrowTopRightOnSquareIcon
                  className={`xl:h-5 xl:w-5  lg:h-4 lg:w-4 sm:h-4 sm-h-4  text-base ${isParticipantsPopupOpened ? "text-blue hover:text-blue" : "text-gray-500 hover:text-gray-900"} hover:cursor-pointer transition-colors `}
                  onClick={openParticipantsPopup}
                  title="Popout Participants"
                />
                <XMarkIcon
                  className="xl:h-5 xl:w-5  lg:h-4 lg:w-4 sm:h-4 sm-h-4  text-base text-gray-500 hover:text-gray-900 hover:cursor-pointer transition-colors "
                  onClick={handleParticipantsClose}
                />
              </div>
            }
          </div>

          <div className="relative rounded-md shadow-sm w-full mt-1 ">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="text"
              id="text"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-00 sm:text-sm sm:leading-6 focus:outline-none"
              placeholder="Search Participants"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div
          className={`w-full h-screen overflow-y-scroll scrollbar  flex flex-col justify-start items-start p-1 gap-2 `}
        >
          <div
            className={`flex justify-between items-start w-full text-sm font-sans shadow-sm rounded-lg border-[.5px] ${handRaised ? "border-yellow-400" : "border-gray-400"
              }  p-1 `}
          >
            <div className="flex justify-between items-center gap-2 w-[90%] h-full ">
              <div className=" flex justify-start items-center gap-1 w-full">
                {personalization.personalization &&
                  personalization.personalization?.imageUrl &&
                  imageError ? (
                  <img
                    className={`inline-block h-7 w-7 rounded-full ring-2 ring-white ${peerVolume && getVolumeClasses(peerVolume[userId])
                      }`}
                    src={personalization.personalization?.imageUrl}
                    alt="User"
                  />
                ) : personalization.personalization &&
                  personalization.personalization?.imageUrl &&
                  !imageError ? (
                  <img
                    className={`inline-block h-7 w-7 rounded-full ring-2 ring-white  ${peerVolume && getVolumeClasses(peerVolume[userId])
                      }`}
                    src={generateThumbnailsUrl(
                      personalization.personalization?.imageUrl
                        .split("/")
                        .pop(),
                      240,
                      custId,
                      userId,
                      spacesConfig
                    )}
                    alt="User"
                    onError={() => setImageError(true)}
                  />
                ) : (
                  <span
                    className={`inline-flex lg:h-8 lg:w-8 xl:h-10 xl:w-10 sm:h-10 sm:w-10  items-center justify-center rounded-full bg-white ${peerVolume && peerVolume[userId] !== -100
                      ? "border-[1.5px] border-blue"
                      : "border-[.5px] border-gray-400 "
                      }`}
                  >
                    <span className="text-sm font-medium leading-none text-blue">
                      {!handRaised ? (
                        getInitials(`${firstName} ${lastName}`)
                      ) : (
                        <HandRaisedIcon className="h-5 w-5 text-yellow-500" />
                      )}
                    </span>
                    {/* {getInitials(`Anubhav Srivastava`)} */}
                  </span>
                )}

                <div className=" flex  flex-col justify-start items-start w-4/5">
                  <p
                    className=" text-sm text-gray-900 font-semibold truncate capitalize w-full"
                    title={`${firstName} ${lastName}`}
                  >
                    {`${firstName} ${lastName}`} (You)
                  </p>
                  <div className="flex justify-center items-center gap-1">
                    <p className=" text-sm text-gray-900  pr-1 border-gray-500">
                      {role}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {constantMethod.checkHigherRole(role) && (
              <div className="w-[5%] flex flex-col justify-between h-full items-end">
                <div key={userId} className="relative">
                  <EllipsisVerticalIcon
                    className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-900"
                    aria-hidden="true"
                    title="Options"
                    onClick={() => handleMenuUserId(userId)}
                  />
                  {userIdForMenu === userId && (
                    <div className="absolute right-4 top-4 flex flex-col w-40 justify-start items-start bg-white z-10 rounded-lg shadow-md p-1" ref={participantsRef}>
                      <p
                        onClick={() => promoteUser(userId)}
                        title={"Promote User"}
                        className={`sm:md:lg:text-xs text-gray-900 xl:text-sm p-1 cursor-pointer hover:bg-gray-100 rounded-md w-full`}
                      >
                        Promote User
                      </p>
                    </div>
                  )}
                </div>

              </div>
            )}
          </div>
          {false ? (
            <SkeletonLoading component="Topics" />
          ) : (
            filteredPeers.map((val: string, index) => {
              let peerUser = mediaReducer.remotePeers[val];
              const sessionData = chatReducer.onlineUsers.find(
                (data) => data.peerUserId === peerUser.peerUserId
              );

              return (
                <div
                  key={val}
                  className={`relative flex justify-between cursor-pointer items-start w-full text-sm font-sans shadow-sm rounded-lg border-[.5px] ${sessionData?.emojiForIconPeer &&
                    sessionData.emojiForIconPeer ===
                    emojiEventConst.HANDRAISE &&
                    (constantMethod.checkHigherRole(userReducer.role) ||
                      agentToagentStatus || constantMethod.checkHigherRole(peerUser.peerType))
                    ? "border-yellow-400"
                    : "border-gray-400"
                    }  p-1 `}
                  style={{
                    border:
                      tempRoomUser.breakParticipants.find(
                        (val: BreakParticipant) =>
                          val.userId === peerUser.peerUserId
                      ) && "1.5px solid var(--blue)",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // socketInstance.sendMessage("CHECK_CONSUMER", {
                    //   peerSessionId: peer.peerSessionId,
                    // });


                    if ((constantMethod.checkHigherRole(role) && e.ctrlKey) || (constantMethod.checkHigherRole(role) && e.metaKey)) {
                      dispatch(setTempRoomUser({ userId: peerUser.peerUserId, username: peerUser.peerUsername }));
                    }
                  }}
                >
                  <div
                    className="flex justify-between items-center gap-2 w-[90%] h-full "
                    onDoubleClick={() => {
                      if (performRightShiftByOne) {
                        console.log("perform");
                        performRightShiftByOne(peerUser.peerUserId, false);
                      }
                    }}
                  >
                    <div className=" flex justify-start items-center gap-1 w-full">
                      {sessionData?.emojiForIconPeer &&
                        sessionData.emojiForIconPeer ===
                        emojiEventConst.HANDRAISE &&
                        (constantMethod.checkHigherRole(userReducer.role) ||
                          agentToagentStatus || constantMethod.checkHigherRole(peerUser.peerType)) ? (
                        <HandRaisedIcon className="h-5 w-5 text-yellow-500" />
                      ) : peerUser.imageUrl && imageError ? (
                        <img
                          className={`inline-block h-8 w-8 rounded-full ring-2 ring-white ${peerVolume &&
                            getVolumeClasses(peerVolume[peerUser.peerUserId])
                            }`}
                          src={peerUser.imageUrl}
                          alt="User"
                        />
                      ) : peerUser.imageUrl && !imageError ? (
                        <img
                          className={`inline-block h-8 w-8 rounded-full ring-2 ring-white  ${peerVolume &&
                            getVolumeClasses(peerVolume[peerUser.peerUserId])
                            }`}
                          src={generateThumbnailsUrl(
                            peerUser?.imageUrl.split("/").pop(),
                            240,
                            custId,
                            peerUser.peerUserId,
                            spacesConfig
                          )}
                          alt="User"
                          onError={() => setImageError(true)}
                        />
                      ) : (
                        <span
                          className={`inline-flex lg:h-8 lg:w-8 xl:h-10 xl:w-10 sm:h-10 sm:w-10  items-center justify-center rounded-full bg-white ${peerVolume &&
                            peerVolume[peerUser.peerUserId] !== -100
                            ? "border-[1.5px] border-blue"
                            : "border-[.5px] border-gray-400 "
                            }`}
                        >
                          <span className="text-sm font-medium leading-none text-blue">
                            {
                              // (sessionData?.emojiForIconPeer && sessionData.emojiForIconPeer === emojiEventConst.HANDRAISE) && (constantMethod.checkHigherRole(userReducer.role) || agentToagentStatus) ? (
                              //   <HandRaisedIcon className="h-5 w-5 text-yellow-500" />
                              // ) : (
                              getInitials(peerUser.peerName)
                              // )
                            }
                          </span>
                          {/* {getInitials(`Anubhav Srivastava`)} */}
                        </span>
                      )}

                      <div className=" flex  flex-col justify-start items-start w-4/5">
                        <p
                          className={` text-sm ${peerUser && peerUser?.numfaces < 0 ? "text-gray-700" : peerUser.numfaces === 0
                            ? "text-red-600"
                            : !peerUser.faceVerificationSuccess &&
                              peerUser.isNotSamePerson &&
                              peerUser.showAlertInParticipant
                              ? "text-yellow-400"
                              : peerUser.expression === "happy"
                                ? "text-green-500"
                                : peerUser.expression === "angry"
                                  ? "text-blue-300"
                                  : peerUser.expression === "sad"
                                    ? "text-blue"
                                    : peerUser.expression === "surprise"
                                      ? "text-pink-400"
                                      : peerUser.expression === "neutral"
                                        ? "text-gray-700"
                                        : "text-gray-700"
                            }  font-semibold truncate capitalize w-full`}
                          title={peerUser.peerName}
                        >
                          {peerUser.peerName}
                        </p>
                        <div className="flex justify-center items-center gap-1">
                          <p className=" text-sm text-gray-900  pr-1 border-r-[1px] border-gray-500">
                            {peerUser.peerType}
                          </p>

                          <div className=" flex justify-center items-end gap-1">
                            {(constantMethod.checkHigherRole(
                              userReducer.role
                            ) ||
                              agentToagentStatus || constantMethod.checkHigherRole(peerUser.peerType)) && (
                                <>
                                  {/* LUNCH, BREAK, BRB, DND, MEETING etc all events icons */}
                                  {sessionData?.emojiForHandAndLunchPeer &&
                                    sessionData.emojiForHandAndLunchPeer ===
                                    emojiEventConst.BRB && (
                                      <ReceiptRefundIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Be Right Back"
                                      />
                                    )}

                                  {sessionData?.emojiForHandAndLunchPeer &&
                                    sessionData.emojiForHandAndLunchPeer ===
                                    emojiEventConst.LUNCH && (
                                      <InboxStackIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Lunch"
                                      />
                                    )}
                      
                                  {sessionData?.emojiForDndPeer &&
                                    sessionData.emojiForDndPeer ===
                                    emojiEventConst.DND && (
                                      <NoSymbolIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Do Not Disturb"
                                      />
                                    )}

                                  {sessionData?.emojiForHandAndLunchPeer &&
                                    sessionData.emojiForHandAndLunchPeer ===
                                    emojiEventConst.BREAK && (
                                      <ClockIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Break"
                                      />
                                    )}

                                  {sessionData?.emojiForMeetingPeer &&
                                    sessionData.emojiForMeetingPeer ===
                                    emojiEventConst.MEETING && (
                                      <UserGroupIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Meeting"
                                      />
                                    )}

                                  {sessionData?.emojiForIconPeer &&
                                    sessionData.emojiForIconPeer ===
                                    emojiEventConst.SMILE && (
                                      <FaceSmileIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Smile"
                                      />
                                    )}

                                  {sessionData?.emojiForIconPeer &&
                                    sessionData.emojiForIconPeer ===
                                    emojiEventConst.CANTHEAR && (
                                      <SpeakerXMarkIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Can't Hear"
                                      />
                                    )}

                                  {sessionData?.emojiForIconPeer &&
                                    sessionData.emojiForIconPeer ===
                                    emojiEventConst.YES && (
                                      <HandThumbUpIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Yes"
                                      />
                                    )}

                                  {sessionData?.emojiForIconPeer &&
                                    sessionData.emojiForIconPeer ===
                                    emojiEventConst.NO && (
                                      <HandThumbDownIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="No"
                                      />
                                    )}

                                  {sessionData?.emojiForIconPeer &&
                                    sessionData.emojiForIconPeer ===
                                    emojiEventConst.TECHNICALISSUE && (
                                      <Cog8ToothIcon
                                        className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                        aria-hidden="true"
                                        title="Support"
                                      />
                                    )}

                                  {/* LUNCH, BREAK, BRB , DND , MEETING Timer */}
                                  {sessionData?.time &&
                                    sessionData.time !== "" && (
                                      <p className=" lg:text-xs xl:sm:text-sm">
                                        {getFormattedMinutes(
                                          sessionData.time,
                                          2,
                                          " Hour "
                                        )}
                                      </p>
                                    )}
                                  {peerUser?.isPrivateCallOn &&
                                    <div className="w-3 h-3 rounded-full bg-red-600"></div>
                                  }
                                </>
                              )}

                            {/* EMOTIONS DETECTION EMOJIS*/}
                            {/* <p className=" lg:text-xs xl:sm:text-sm">😂</p> */}
                            <p className=" lg:text-xs xl:sm:text-sm">
                              {peerUser.expression === "happy"
                                ? "😊"
                                : peerUser.expression === "sad"
                                  ? "😢"
                                  : peerUser.expression === "angry"
                                    ? "😠"
                                    : peerUser.expression === "surprise"
                                      ? "😯"
                                      : peerUser.expression === "neutral"
                                        ? "🙂"
                                        : ""}
                            </p>

                            {peerUser.objectDetect ? (
                              <DevicePhoneMobileIcon
                                className="h-4 w-4 text-red-600 cursor-pointer hover:text-red-600"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                            <SignalIndicator
                              networkStrength={peerUser.networkStrength}
                              fromParticipant={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[5%] flex flex-col justify-between h-full items-end">
                    <div key={peerUser.peerUserId} className="relative">
                      {(userReducer.role !== 'Agent' || (((constantMethod.checkHigherRole(role) || constantMethod.checkHigherRole(peerUser.peerType) || mediaReducer.presenter === userReducer.userId) || (!privateCallPermission && (constantMethod.checkHigherRole(peerUser.peerType))) || (privateCallPermission && (constantMethod.checkHigherRole(peerUser.peerType) || !constantMethod.checkHigherRole(peerUser.peerType)))) && (sessionData && sessionData.emojiForHandAndLunchPeer === "" && sessionData.emojiForDndPeer === "" && sessionData.emojiForMeetingPeer==="" ) && ((!isPrivateCallOn && !callModalOption.isCallModal) ||
                        openentCallerUserId === peerUser.peerUserId))) && <EllipsisVerticalIcon
                          className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-900"
                          aria-hidden="true"
                          title="Options"
                          onClick={() => handleMenuUserId(peerUser.peerUserId)}
                        />}

                      {userIdForMenu === peerUser.peerUserId && (
                        <div
                          className={`${index === 0 ? "top-4" : "bottom-1"} absolute right-4 bg-white overflow-y-auto p-1 rounded-xl shadow-md w-40 transition duration-500 z-10`}
                          ref={participantsRef}
                          onClick={() => setUserIdForMenu("")}
                        >

                          {sessionData && sessionData.emojiForHandAndLunchPeer==="" && sessionData.emojiForDndPeer === "" && sessionData.emojiForMeetingPeer==="" && (roomReducer.isAudioAllowedInRoom || roomReducer.isAllowPrivateCallInSilentRoom) && ((constantMethod.checkHigherRole(role) || constantMethod.checkHigherRole(peerUser.peerType) || mediaReducer.presenter === userReducer.userId) || (!privateCallPermission && (constantMethod.checkHigherRole(peerUser.peerType))) || (privateCallPermission && (constantMethod.checkHigherRole(peerUser.peerType) || !constantMethod.checkHigherRole(peerUser.peerType)))) && ((!isPrivateCallOn && !callModalOption.isCallModal) ||
                            openentCallerUserId === peerUser.peerUserId ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() => handlePrivateCall(peerUser)}
                            >
                              <p
                                title={"Private Call"}
                                className="md:lg:sm:text-xs xl:text-sm"
                              >
                                {isPrivateCallOn &&
                                  peerUser.isPrivateCallOn &&
                                  openentCallerUserId === peerUser.peerUserId
                                  ? "Stop Private Call"
                                  : isPrivateCallOn && !peerUser.isPrivateCallOn
                                    ? "Your private call on"
                                    : peerUser.isPrivateCallOn ||
                                      (!peerUser.isPrivateCallOn &&
                                        peerUser.isCallModalOn)
                                      ? "Already in Call"
                                      : "Private Call"}
                              </p>
                            </div>
                          ) : null)}


                          {roomReducer.isAudioAllowedInRoom && !isPrivateCallOn &&
                            !callModalOption.isCallModal &&
                            peerUser.peerAudioPaused &&
                            !peerUser.isCallModalOn &&
                            !peerUser.isPrivateCallOn &&
                            peerUser.peerType === "Agent" &&
                            constantMethod.checkHigherRole(role) &&
                            environmentReducer.environments
                              .SUPERVISOR_CAN_OBSERVE ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() =>
                                handleStartAndStopObserveAgent(
                                  peerUser.peerUserId,
                                  peerUser.openentObserverAudioId
                                )
                              }
                            >
                              <p
                                title={"Observe"}
                                className="md:lg:sm:text-xs xl:text-sm"
                              >
                                {openentObserveeAudioId === peerUser.peerUserId
                                  ? "Stop Observing"
                                  : openentObserveeAudioId !== "" &&
                                    openentObserveeAudioId !==
                                    peerUser.peerUserId
                                    ? "Can't Observe"
                                    : peerUser.openentObserverAudioId !== ""
                                      ? "Already Observed"
                                      : "Observe"}
                              </p>
                            </div>
                          ) : null}

                          {constantMethod.checkHigherRole(userReducer.role) && roomViewModeConst.lectureMode === roomReducer.viewMode ? <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            onClick={() => promoteUser(peerUser.peerUserId)}
                          >
                            <p
                              title={"Promote User"}
                              className="md:lg:sm:text-xs xl:text-sm"
                            >
                              Promote User
                            </p>
                          </div> : null}

                          {constantMethod.checkHigherRole(role) ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() =>
                                handleRemoveParticipant(
                                  peerUser.peerUserId,
                                  peerUser.peerUsername,
                                  peerUser.peerName
                                )
                              }
                            >
                              <p
                                title={"Remove Participant"}
                                className="md:lg:sm:text-xs xl:text-sm"
                              >
                                Remove Participant
                              </p>
                            </div>
                          ) : null}

                          {(constantMethod.checkHigherRole(role) && sessionData?.emojiForIconPeer && sessionData.emojiForIconPeer === emojiEventConst.HANDRAISE && supervisorCanRemoveHandraise) ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() =>
                                handleRemoveHandraise(
                                  peerUser.peerUserId,
                                  peerUser.peerUsername,
                                  peerUser.peerName
                                )
                              }
                            >
                              <p
                                title={"Remove Handraise"}
                                className="md:lg:sm:text-xs xl:text-sm"
                              >
                                Remove Handraise
                              </p>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>

                    {
                      !constantMethod.checkHigherRole(peerUser.peerType) && constantMethod.checkHigherRole(role) &&
                      <p className="flex justify-center items-center gap-1 text-xs text-gray-500">
                        {(peerUser.headDownCount >= (Number(headPoseSeconds) || 3) && peerUser.headUpCount < (Number(headPoseSeconds) || 3)) && (
                          <p className="w-[12px] h-[12px]">
                            <img src={headIcon} className="transform -scale-x-100" alt="head Down Icon" />
                          </p>
                        )}
                        <UsersIcon className="h-3 w-3 text-gray-500" />{peerUser.numfaces >= 0 ? peerUser.numfaces : 0}
                      </p>
                    }
                  </div>
                </div>
              );
            })
          )}
        </div>

        {constantMethod.checkHigherRole(role) &&
          tempRoomUser.breakParticipants.length > 0 &&
          !open ? (
          <div className={`absolute  bottom-3  flex justify-center items-center w-full`}>
            <button
              className="inline-flex justify-center rounded-md shadow-md border-[1px] border-blue-50 bg-white  p-1 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2 w-1/2"
              // className="bg-blue w-4/5 text-white rounded-lg text-sm p-1 shadow-lg"
              onClick={() => {
                setOpen(true);
              }}
            >
              Move Participants
            </button>
          </div>
        ) : null}
      </div>

      {/* Breakout room modal  */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[40%] sm:min-h-[40vh] max-h-[55vh] overflow-y-scroll scrollbar sm:p-6">
                  <p
                    className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                    title="Close"
                    onClick={() => {
                      setBreakoutValue("duration", "");
                      setBreakoutValue("supervisor", "");
                      setBreakoutValue("roomName", "");
                      setTempRoomType("");
                      setOpen(false);
                    }}
                  >
                    <XMarkIcon className=" h-5 w-5" />
                  </p>
                  <div className=" flex flex-col justify-start items-center gap-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Move Participants
                    </Dialog.Title>

                    {tempRoomType === "breakout" ? (
                      <div className=" w-full p-2 ">
                        <form
                          onSubmit={handleBreakoutFormSubmit(onBreakoutSubmit)}
                          className="flex flex-col justify-start items-center gap-2 "
                        >
                          <Input
                            {...registerBreakoutForm("roomName", {
                              required: true,
                            })}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setBreakoutValue("roomName", e.target.value, {
                                shouldValidate: true,
                              });
                            }}
                            type="text"
                            className="w-full"
                            placeholder="Enter Roomname"
                            error={breakoutFormErrors.roomName}
                            message={
                              breakoutFormErrors.roomName &&
                              "Roomname is required*"
                            }
                          />
                          <Input
                            {...registerBreakoutForm("duration", {
                              required: true,
                            })}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setBreakoutValue("duration", e.target.value, {
                                shouldValidate: true,
                              });
                            }}
                            type="text"
                            className="w-full"
                            placeholder="Duration in Days(1-30)"
                            error={breakoutFormErrors.duration}
                            message={
                              breakoutFormErrors.duration &&
                              "Duration is required*"
                            }
                          />

                          <select
                            {...registerBreakoutForm("supervisor", {
                              required: true,
                            })}
                            name="supervisor"
                            id=""
                            className={` w-full border-2 border-gray-400 rounded-md p-2 focus:outline-blue   ${breakoutFormErrors.supervisor &&
                              "border-red-600 focus:border-red-600"
                              }`}
                          >
                            <option value="">Select Supervisor</option>
                            {tempRoomUser.breakParticipants &&
                              tempRoomUser.breakParticipants.map(
                                (val: BreakParticipant) => (
                                  <option
                                    className=" border-1 border-gray-400 p-1 text-base "
                                    value={val.userId}
                                  >
                                    {val.username}
                                  </option>
                                )
                              )}
                          </select>
                          {breakoutFormErrors.supervisor && (
                            <p
                              className="lg:mt-[2px] mr-auto xl:mt-2 text-sm text-red-600"
                              id="email-error"
                            >
                              Supervisor is required*
                            </p>
                          )}
                          <div className=" w-full flex justify-center items-center gap-3">
                            <button
                              className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700  focus-visible:ring-offset-2"
                              // className="bg-blue w-1/2 text-white rounded-lg text-sm p-1 shadow-lg"
                              onClick={() => setTempRoomType("")}
                              children="Cancel"
                            />
                            <button
                              className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                              // className="bg-blue w-1/2 text-white rounded-lg text-sm p-2 shadow-lg"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : tempRoomType === "existing" ? (
                      <form
                        onSubmit={handleExistingSubmit(onExistingSubmit)}
                        className="flex flex-col justify-start items-center gap-2 w-full"
                      >
                        <select
                          {...registerExistingRoom("room", { required: true })}
                          name="room"
                          id=""
                          className={` w-full border-2 border-gray-400 rounded-md p-2 focus:outline-blue   overflow-y-auto overflow-x-hidden  ${existingErrors.room &&
                            "border-red-600 focus:border-red-600"
                            }`}
                        >
                          <option value="">Select Room</option>
                          {roomReducer.rooms &&
                            roomReducer.rooms.filter((val: any) => val._id !== roomReducer.currentRoomId).map((val: any) => (
                              <option
                                className=" border-1 border-gray-400 p-1 text-base "
                                value={val._id}
                              >
                                {val.roomName}
                              </option>
                            ))}
                        </select>
                        {existingErrors.room && (
                          <p
                            className="lg:mt-[2px] mr-auto xl:mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            Room is required*
                          </p>
                        )}

                        <div className=" w-full flex justify-center items-center gap-3">
                          <button
                            className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700  focus-visible:ring-offset-2"
                            // className="bg-blue w-1/2 text-white rounded-lg text-sm p-1 shadow-lg"
                            onClick={() => {
                              setTempRoomType("");
                            }}
                            children="Cancel"
                          />
                          <button
                            className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                            // className="bg-blue w-1/2 text-white rounded-lg text-sm p-2 shadow-lg"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div className=" w-full flex justify-center items-center gap-7 ">
                        {tempRoomUser.breakParticipants.length > 1 && (
                          <button
                            // className="bg-blue w-1/2 text-white rounded-lg text-sm p-1 shadow-lg"
                            className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                            onClick={() => setTempRoomType("breakout")}
                          >
                            Move to Breakout Room
                          </button>
                        )}

                        <button
                          className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                          // className="bg-blue w-1/2 text-white rounded-lg text-sm p-1 shadow-lg"
                          onClick={() => setTempRoomType("existing")}
                        >
                          Move to Existing Room
                        </button>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
