import { useAppSelector } from "../../app/hooks";
import DashboardLayout from "../../components/DashboardLayout";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";

interface TrainingLink {
    label: string;
    url: string;
}

const trainingLinks = (role: string): TrainingLink[] => {
    const links: TrainingLink[] = [];

    if (role === "Agent" || checkForSupManAdmin(role, "equalTo")) {
        links.push({ label: "Agent Training", url: "https://agenttraining4.collaborationroom.ai/" });
    }

    if (checkForSupManAdmin(role, "equalTo")) {
        links.push({
            label: "Supervisor Training",
            url: "https://supervisortraining4.collaborationroom.ai/",
        });
    }

    if (role === "Admin") {
        links.push({
            label: "Administrator Training",
            url: "https://admintraining.collaborationroom.ai/",
        });
    }

    return links;
};

export default function Training(): ReturnType<React.FC> {
    const user = useAppSelector(userDetailsSelector);
    const { role } = user;

    const links = trainingLinks(role);

    return (
        <DashboardLayout>
            <div
                style={{ height: "calc(100vh - (5*24px) + 9px)" }}
                className={`w-full bg-white relative rounded-2xl pt-3 pb-1 flex flex-col justify-start items-start overflow-y-auto scrollbar`}
            >
                <div className="container flex justify-between pl-6 pr-6">
                    <h2 className="font-sans font-medium xl:text-3xl lg:text-2xl text-gray-900 self-stretch">
                        Training
                    </h2>
                </div>

                <div className="w-full border-t-[0.5px] border-gray-400 mt-4" />

                <div className="flex flex-col justify-center gap-3 items-start mt-4 p-6 w-full">
                    {links.map((link, index) => (
                        <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-full text-blue-600 text-black hover:text-blue hover:bg-gray-100 font-medium text-sm md:text-base px-6 py-4 rounded-md  hover:bg-blue-500 transition-all duration-300 shadow-md"
                        >
                            {link.label}
                        </a>
                    ))}
                </div>
            </div>
        </DashboardLayout>
    );
}