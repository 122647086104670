import { authRequest } from "./index";
import { getToken } from "../utils/Token";
import axios from "axios";

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: '',
    },
};

export const processIPfromWebrtc = async (ipaddress: string, roomId?: string | null) => {

    let response = await axios.get(
        `${process.env.REACT_APP_ABSTRACTAPI_BASE_URL}?api_key=${process.env.REACT_APP_ABSTRACTAPI_KEY}&ip_address=${ipaddress}`,
        {
            timeout: 2000,
        }
    );

    config["headers"]["Authorization"] = getToken();
    const body: { abstractAPIresponse: any; roomId?: string | null} = {
        abstractAPIresponse: response.data,
    };

    if(roomId){
        body.roomId = roomId || null;
    }

    // const result = await
    authRequest.post("/v1/api/userlocation/savelocationwebrtc", body, config);
    localStorage.setItem("webrtcipfetched", "true");
    return;
};

