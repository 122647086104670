
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouter from './routes/AppRouter';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';



const customToastOptions = {
  default: {
    style: {
      border: "1px solid #4EA3DB", // Blue border for default toasts
      width: "300px", // Set t
      padding: "10px"
    }
  }
  ,
  success: {
    style: {
      border: "2px solid #4caf50",
      width: "300px",
      padding: "10px",
      overflow:"hidden"

    },
    icon: null

  },
  error: {
    style: {
      border: "2px solid #f44336",
      width: "300px",
      padding: "10px"

    },
    icon: null

  },
};

function App() {


  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js')
        .then((registration) => {
          console.log('Service Worker registered:', registration);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }, []);


  return (
    <div>

      <Toaster
        position="bottom-left"
        toastOptions={customToastOptions}
        reverseOrder={true}
        containerStyle={{
          marginBottom: '5%' // Adds 5% of space from the bottom
        }}


      />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
//
