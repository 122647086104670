import React, { useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import styles from './styles.module.css';
import ChatDetails from '../ChatDetails';
import { ChatListProps } from '../../interfaces/componentsInterface/chats';
import { chatSelector, setSelectedRoomData, } from '../../features/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { userDetailsSelector } from '../../features/userDetails/userDetailsSlice';
import { setSelectedChatUser, setSelectedChatType } from '../../features/chat/chatSlice';
import { getInitials } from '../../utils/initials';
import SkeletonLoading from '../SkeletonLoading';
import { fetchChatListing } from '../../services/chat.service';
import TimeAgo from '../TimeAgo';
import { roomSelector } from '../../features/room/roomSlice';
import { AGENT_TO_AGENT_CHAT, chatType } from '../../utils/constant';
import { generateRandomQuote } from '../../utils/quotes';
import { UsersIcon } from '@heroicons/react/20/solid';
import { personalizationSelector } from '../../features/personalization/personalization';
import { environmentSelector } from '../../features/environment/environmentSlice';
import { generateThumbnailsUrl } from '../../utils/generateImageURL';
import { constantMethod } from '../../utils/constantMethod';
import RoomImage from '../../assets/images/room.jpg'
import toast from 'react-hot-toast';
import { mediaSelector } from '../../features/media/mediaSlice';

export default function ChatList({ active, setActive, isChatPopupOpened, fromPopup, popupWindow }: ChatListProps): ReturnType<React.FC> {

    const dispatch = useAppDispatch()
    const environments = useAppSelector(environmentSelector);
    const personalization = useAppSelector(personalizationSelector);
    const mediaReducer = useAppSelector(mediaSelector)
    const user = useAppSelector(userDetailsSelector)
    const [imageError, setImageError] = useState(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const chat = useAppSelector(chatSelector);
    const loggedInUser = useAppSelector(userDetailsSelector);
    const [filteredUsers, setFilteredUsers] = useState(chat.onlineUsers);
    const roomDetails = useAppSelector(roomSelector);
    const [quote, setQuote] = useState<{ quote: string; author: string; }>();
    const [filteredRooms, setFilteredRooms] = useState<any[]>([]);
    const [filteredChatListing, setFilteredChatListing] = useState<any[]>([]);
    const [onlineUsersNotInChatListing, setOnlineUsersNotInChatListing] = useState<any[]>([]);
    const chatDetails = useAppSelector(chatSelector);

    const spacesConfig = environments.environments && environments.environments.REACT_APP_SPACES_CONFIG ? environments.environments.REACT_APP_SPACES_CONFIG.data[0] : '';

    const agentToAgentChat = environments.environments && environments.environments.AGENT_TO_AGENT_CHAT ? environments.environments.AGENT_TO_AGENT_CHAT.status : AGENT_TO_AGENT_CHAT
    const toggleChatView = (data: any, selectionType: string) => {

        // console.log(user.role,data.peerType , data  ,  agentToAgentChat)
        if (data.userId && user.userId !== data.userId && !constantMethod.checkHigherRole(user.role) && !constantMethod.checkHigherRole(data.peerType) && !agentToAgentChat && mediaReducer.presenter !== data.userId && mediaReducer.presenter !== user.userId) {
            toast.error("Agent to agent chat is Disabled.")
            return;
        }

        setShowDetails(true);
        if (selectionType === chatType.PRIVATE) {
            setUserDetails(data);
            dispatch(setSelectedChatUser(data));
            dispatch(setSelectedChatType(selectionType));
        } else if (selectionType === chatType.SAMEROOM) {
            let roomData = {
                currentRoom: data.currentRoom,
                chatType: selectionType,
                selectedRoomId: data.currentRoomId,
                bussId: data.currentRoomBussId
            }
            dispatch(setSelectedRoomData(roomData));
        } else if (selectionType === chatType.SELF) {
            let selfData = {
                peerName: loggedInUser.firstName + ' ' + loggedInUser.lastName,
                userId: loggedInUser.userId,
                peerUsername: loggedInUser.username,
                peerType: loggedInUser.role,
                chatType: chatType.PRIVATE, // Don't change it, SELF we treat as PRIVATE. SELF keyword is for conditions only.
                peerSessionId: "",
                peerUserId: "",
                emojiForIconPeer: "",
                emojiForHandAndLunchPeer: "",
                emojiForMeetingPeer: "",
                emojiForDndPeer: "",
                isPrivateCallOn: "",
                isCallModalOn: "",
                networkStrength: "",
                time: "",
                selectedRoom: data.currentRoom,
                imageUrl: "",
                selectedRoomId: data.currentRoomId,
                selectedRoomBussId: data.currentRoomBussId,
            }
            dispatch(setSelectedChatUser(selfData));
        } else if (selectionType === chatType.OTHERROOM) {
            let roomData = {
                currentRoom: data.roomName,
                chatType: selectionType,
                selectedRoomId: data._id,
                bussId: data.bussId
            }
            dispatch(setSelectedRoomData(roomData));
        }
    }

    useEffect(() => {
        // Set initial values for filtered data when component mounts or data changes
        setFilteredUsers(chat.onlineUsers);
        setFilteredRooms(roomDetails.rooms);
        setFilteredChatListing(chat.chatListing);
        setOnlineUsersNotInChatListing(
            chat.onlineUsers.filter(
                onlineUser =>
                    onlineUser.userId !== loggedInUser.userId &&
                    !chat.chatListing.some(chatUser => chatUser.userId === onlineUser.userId)
            )
        );
    }, [chat.onlineUsers, roomDetails.rooms, chat.chatListing, loggedInUser.userId]);


    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }


    useEffect(() => {
        // Filter results based on search value
        const filteredUsers = chat.onlineUsers.filter(user =>
            user.peerName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            user.peerUsername?.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredUsers(filteredUsers);

        const filteredRooms = roomDetails.rooms.filter(room =>
            room.roomName?.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredRooms(filteredRooms);

        const filteredChatListing = chat.chatListing.filter(user =>
            user.peerName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            user.peerUsername?.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredChatListing(filteredChatListing);

        const onlineUsersNotInChatListing = chat.onlineUsers.filter(
            onlineUser =>
                onlineUser.userId !== loggedInUser.userId &&
                !chat.chatListing.some(chatUser => chatUser.userId === onlineUser.userId) &&
                (onlineUser.peerName?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    onlineUser.peerUsername?.toLowerCase().includes(searchValue.toLowerCase()))
        );
        setOnlineUsersNotInChatListing(onlineUsersNotInChatListing);
    }, [searchValue, chat.onlineUsers, chat.chatListing, roomDetails.rooms, loggedInUser.userId]);

    useEffect(() => {
        const randomQuote = generateRandomQuote();
        setQuote(randomQuote);
    }, [])
    useEffect(() => {
        let queryData = {
            loggedInUserId: loggedInUser.userId,
            clientId: loggedInUser.custId,
            roomId: roomDetails.currentRoomId
        }
        dispatch(fetchChatListing(queryData))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const transformMessage = (message: string): string => {
        // Regular expression to match @[name](name)
        const regex = /@\[(.+?)\]\(.+?\)/g;
        // Replace with @name
        return message.replace(regex, (match, p1) => `@${p1}`);
    };


    return (
        <div className={`${active ? 'lg:md:h-[68%]' : 'lg:md:h-[85%]'} ${isChatPopupOpened && "h-screen scrollbar"} flex flex-col items-center justify-start`}>

            {
                isChatPopupOpened && fromPopup && !showDetails && <h1
                    title="Amplify Your Experience with Chats and Feeds"
                    className=" font-sans text-gray-900 text-base font-semibold"
                >
                    Chats
                </h1>
            }

            {
                !showDetails &&
                <div className="relative rounded-md shadow-sm w-[90%] mt-1 ">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="text"
                        id="text"
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-00 sm:text-sm sm:leading-6 focus:outline-none"
                        placeholder="Search Chats"
                        value={searchValue}
                        onChange={handleSearch}
                    />
                </div>
            }


            {

                isChatPopupOpened && !fromPopup ? <div className=' h-full w-full flex flex-col justify-start items-center  relative '>
                    <p className=' text-center text-sm w-11/12 font-semibold text-gray-500 shadow-md border-[1px] border-blue p-2 rounded-md  absolute bottom-10 '>Close Newly Opened Chat Tab to Continue Viewing Conversation.</p>

                    {
                        !active && <div className=' flex flex-col justify-start items-start w-11/12 mt-4'>

                            <p className='text-blue leading-relaxed text-sm'>Always Remember:</p>
                            <p className=' text-gray-500 text-sm  '>{quote?.quote}</p>
                            <p className=' text-gray-400 text-sm ml-auto italic'>- {quote?.author}</p>
                        </div>
                    }

                </div> :


                    <>{
                        !showDetails ? <div className={`flex flex-col  overflow-x-hidden justify-start items-start  w-full ${styles.height} ${!chat.loading ? `${isChatPopupOpened ? " overflow-y-scroll scrollbar h-screen" : "overflow-y-scroll scrollbar"}` : 'overflow-hidden'} `} >


                            {
                                chat.loading ? <SkeletonLoading component='Chat' /> :
                                    <>
                                        {
                                            /*
                                             * ###############################
                                             * The order of chat listing display:
                                             * 1. SELF USER
                                             *    Self user chat to save notes etc
                                             *
                                             * 2. ROOM CHATS
                                             *    Display chat rooms, which are listed independently.
                                             *
                                             * 3. ALL ROOMS EXCEPT CURRENT (FOR DASHBOARD, HIGER ROLES ONLY)
                                             *    Display chat rooms, which are listed independently.
                                             *
                                             * 4. Past Chat Users who are ONLINE
                                             *    Filter past chat users who are currently online.
                                             *    This involves checking if the user is present in both `chatListing` and `onlineUsersList`.
                                             *
                                             * 5. New ONLINE Users
                                             *    Display users who are online but haven't chatted yet.
                                             *    Filter the users who are present in `onlineUsersList` but not in `chatListing`.
                                             *
                                             * 6. Past Chat Users who are OFFLINE
                                             *    Filter past chat users who are offline.
                                             *    This involves checking if the user is present in `chatListing` but not in `onlineUsersList`.
                                             *
                                             * 7. We will display all other users who are assigned to selected room and are offline currently
                                             *##################################
                                             */
                                        }


                                        {/* SELF user*/}
                                        <div className='flex flex-col justify-center items-center pl-4 pb-2 border-b-[0.5px] border-b-gray-300 last-of-type:border-none cursor-pointer hover:bg-gray-50 w-full'
                                            onClick={() => toggleChatView(loggedInUser, chatType.SELF)} >
                                            <div className='flex justify-between items-start w-full p-2'>
                                                <div className='w-full flex justify-start items-center gap-2'>
                                                    <span className="relative h-9 w-9">
                                                        {
                                                            personalization.personalization && personalization.personalization?.imageUrl && imageError ? <img
                                                                className=" h-9 w-9 rounded-full ring-2 ring-white"
                                                                src={personalization.personalization?.imageUrl}
                                                                alt=""

                                                            /> :
                                                                personalization.personalization && personalization.personalization?.imageUrl && !imageError ? <img
                                                                    className=" h-9 w-9 rounded-full ring-2 ring-white"
                                                                    src={generateThumbnailsUrl(personalization.personalization?.imageUrl.split('/').pop(), 240, user.custId, user.userId, spacesConfig)}
                                                                    alt=""
                                                                    onError={() => setImageError(true)}
                                                                /> :
                                                                    <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                                                                        <span className="text-sm font-medium leading-none text-white">{getInitials(`${loggedInUser.firstName + ' ' + loggedInUser.lastName}}`)}</span>
                                                                    </span>}
                                                        <span className={`absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-green-500 ring-2 ring-white`} />
                                                    </span>
                                                    <div className='w-[79%]'>
                                                        <h1 className='font-sans text-gray-900 font-semibold xl:text-lg lg:text-[80%] capitalize w-[99%] truncate' title={loggedInUser.firstName + ' ' + loggedInUser.lastName}>{loggedInUser.firstName + ' ' + loggedInUser.lastName + ' (You)'}</h1>
                                                        <p className='font-sans text-gray-500 self-stretch xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>Your personal space</p>
                                                    </div>
                                                </div>
                                                <p className='text-gray-500 xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>
                                                    {/* <TimeAgo timestamp={user.timestamp} /> */}
                                                </p>
                                            </div>
                                            <p className='w-[95%] text-gray-500 font-sans text-sm tracking-wide break-words'>Save your notes here</p>
                                        </div>


                                        {/* Current Room Chat (EVERYONE)*/}
                                        {chatDetails.popupOpenedFrom === "" &&
                                            <div className='flex flex-col justify-center items-center pl-4 pb-2 border-b-[0.5px] border-b-gray-300 last-of-type:border-none cursor-pointer hover:bg-gray-50 w-full'
                                                onClick={() => toggleChatView(roomDetails, chatType.SAMEROOM)} >
                                                <div className='flex justify-between items-start w-full p-2'>
                                                    <div className='flex justify-center items-center gap-2'>

                                                        <UsersIcon className=' text-blue w-8 h-8 border-[1px]  p-1 border-blue rounded-full' />
                                                        <div>
                                                            <h1 className='font-sans text-gray-900 font-semibold xl:text-lg lg:text-[80%] capitalize'>{roomDetails.currentRoom}</h1>
                                                            <p className='font-sans text-gray-500 self-stretch xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>Current room</p>
                                                        </div>
                                                    </div>
                                                    <p className='text-gray-500 xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>
                                                        {/* <TimeAgo timestamp={user.timestamp} /> */}
                                                    </p>
                                                </div>
                                                <p className='w-[95%] text-gray-500 font-sans text-sm tracking-wide truncate break-words' title="Send Message to everyone in current room">
                                                    {
                                                        chat.typeStatus.active && chat.typeStatus.chatType === chatType.SAMEROOM ? chat.typeStatus.typerName + ' is typing...' : "Send Message to everyone in current room"
                                                    }
                                                </p>
                                            </div>
                                        }

                                        {/* All Rooms except current*/}
                                        {chatDetails.popupOpenedFrom !== "" &&
                                            constantMethod.checkHigherRole(loggedInUser.role) &&
                                            filteredRooms.map((room) => (
                                                room?._id !== roomDetails?.currentRoomId &&
                                                <div className='flex flex-col justify-center items-center pl-4 pb-2 border-b-[0.5px] border-b-gray-300 last-of-type:border-none cursor-pointer hover:bg-gray-50 w-full'
                                                    onClick={() => toggleChatView(room, chatType.OTHERROOM)} >
                                                    <div className='flex justify-between items-start w-full p-2'>
                                                        <div className=' flex justify-center items-center gap-2'>
                                                            {room.imageName !== "" ? (
                                                                <img
                                                                    className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                    src={room?.imageName || RoomImage}
                                                                    alt="User"
                                                                />
                                                            ) : (
                                                                <UsersIcon className='text-blue w-8 h-8 border-[1px] p-1 border-blue rounded-full' />
                                                            )}
                                                            <div className=''>
                                                                <h1 className='font-sans text-gray-900 font-semibold xl:text-lg lg:text-[80%] capitalize truncate'>
                                                                    {room?.roomName}
                                                                </h1>
                                                                <p className='font-sans text-gray-500 self-stretch xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>
                                                                    {room?.roomType}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='w-[95%] text-gray-500 font-sans text-sm tracking-wide break-words'>
                                                        {
                                                            chat.typeStatus.active && chat.typeStatus.chatType === chatType.OTHERROOM ? chat.typeStatus.typerName + ' is typing...' : null
                                                        }
                                                    </p>
                                                </div>
                                            ))


                                        }

                                        {/* Past Users Who are ONLINE*/}
                                        {chatDetails.popupOpenedFrom === "" &&
                                            filteredChatListing
                                                .filter(user => user.userId !== loggedInUser.userId && filteredUsers.some(onlineUser => onlineUser.userId === user.userId))
                                                .map((user) => (
                                                    <div className={`flex flex-col justify-center items-center pl-4 pb-2 border-b-[0.5px] border-b-gray-300 last-of-type:border-none cursor-pointer hover:bg-gray-50 w-full ${(!user.seen && user.sentOrReceived === "received") ? 'bg-gray-100' : ''}`}
                                                        onClick={() => toggleChatView(user, user.chatType)} >
                                                        <div className='flex justify-between items-start w-full p-2'>
                                                            <div className='w-4/6 flex justify-start items-center gap-2'>
                                                                <span className="relative">
                                                                    {user.imageUrl && imageError ? <img
                                                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                        src={user.imageUrl}
                                                                        alt="User"
                                                                    /> : user.imageUrl && !imageError ? <img
                                                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                        src={generateThumbnailsUrl(user?.imageUrl.split('/').pop(), 240, loggedInUser.custId, user.userId, spacesConfig)}
                                                                        alt="User"
                                                                        onError={() => setImageError(true)}
                                                                    /> :
                                                                        <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                                                                            <span className="text-sm font-medium leading-none text-white">{getInitials(`${user.peerName}`)}</span>
                                                                        </span>
                                                                    }
                                                                    {/* Online status */}
                                                                    <span className={`absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-green-500 ring-2 ring-white`} />
                                                                    {!user.seen && user.sentOrReceived === "received" && <span className={`absolute top-0 left-0 block h-2.5 w-2.5 rounded-full bg-red-600 ring-2 ring-white`} />}
                                                                </span>
                                                                <div className='w-4/6'>
                                                                    <h1 className='font-sans text-gray-900 font-semibold xl:text-lg lg:text-[80%] capitalize truncate w-full' title={user.peerName} >{user.peerName}</h1>
                                                                    <p className='font-sans text-gray-500 self-stretch xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>{user.peerType}</p>
                                                                </div>
                                                            </div>
                                                            <p className='text-gray-500 xl:text-sm lg:text-[80%] md:text-[80%] sm:text-[80%] max-w-20'>
                                                                <TimeAgo timestamp={user.timestamp} />
                                                            </p>
                                                        </div>
                                                        <p className={`w-[95%] font-sans text-sm tracking-wide truncate break-words ${(!user.seen && user.sentOrReceived === "received") ? 'font-bold' : ''}`}>
                                                            {
                                                                (chat.typeStatus.active && chat.typeStatus.chatType === chatType.PRIVATE && chat.typeStatus.typerUserId === user.userId) ? 'typing...' : transformMessage(user.latestMessage)
                                                            }
                                                        </p>
                                                    </div>
                                                ))
                                        }


                                        {/* New ONLINE Users */}
                                        {chatDetails.popupOpenedFrom === "" &&
                                            onlineUsersNotInChatListing.map((user) => (
                                                <div className='flex flex-col justify-center items-center pl-4 pb-2 border-b-[0.5px] border-b-gray-300 last-of-type:border-none cursor-pointer hover:bg-gray-50 w-full'
                                                    onClick={() => toggleChatView(user, chatType.PRIVATE)} >
                                                    <div className='flex justify-between items-start w-full p-2'>
                                                        <div className='w-4/6 flex justify-start items-center gap-2'>
                                                            <span className="relative">


                                                                {user.imageUrl && imageError ? <img
                                                                    className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                    src={user.imageUrl}
                                                                    alt="User"

                                                                /> : user.imageUrl && !imageError ? <img
                                                                    className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                    src={generateThumbnailsUrl(user?.imageUrl.split('/').pop(), 240, loggedInUser.custId, user.userId, spacesConfig)}
                                                                    alt="User"
                                                                    onError={() => setImageError(true)}
                                                                /> :



                                                                    <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                                                                        <span className="text-sm font-medium leading-none text-white">{getInitials(`${user.peerName}`)}</span>
                                                                    </span>
                                                                }


                                                                {/* Green dot for online status */}
                                                                <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-green-500 ring-2 ring-white" />
                                                            </span>
                                                            <div className='w-4/6'>
                                                                <h1 className='font-sans text-gray-900 font-semibold xl:text-lg lg:text-[80%] capitalize truncate w-full' title={user.peerName} >{user.peerName}</h1>
                                                                <p className='font-sans text-gray-500 self-stretch xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>{user.peerType}</p>
                                                            </div>
                                                        </div>
                                                        <p className='text-gray-500 xl:text-sm lg:text-[80%] md:text-[80%] sm:text-[80%] max-w-20'>Online</p>
                                                    </div>
                                                    <p className='w-[90%] font-sans text-sm tracking-wide'>
                                                        {
                                                            (chat.typeStatus.active && chat.typeStatus.chatType === chatType.PRIVATE && chat.typeStatus.typerUserId === user.userId) ? 'typing...' : null
                                                        }
                                                    </p>
                                                </div>
                                            ))
                                        }

                                        {/** Past Users who are currently OFFLINE */}
                                        {chatDetails.popupOpenedFrom === "" &&
                                            filteredChatListing
                                                .filter(user => user.userId !== loggedInUser.userId && !filteredUsers.some(onlineUser => onlineUser.userId === user.userId))
                                                .map((user) => (
                                                    <div className='flex flex-col justify-center items-center pl-4 pb-2 border-b-[0.5px] border-b-gray-300 last-of-type:border-none cursor-pointer hover:bg-gray-50 w-full'
                                                        onClick={() => toggleChatView(user, user.chatType)} >
                                                        <div className='flex justify-between items-start w-full p-2'>
                                                            <div className='w-4/6 flex justify-start items-center gap-2'>
                                                                <span className="relative">

                                                                    {user.imageUrl && imageError ? <img
                                                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                        src={user.imageUrl}
                                                                        alt="User"

                                                                    /> : user.imageUrl && !imageError ? <img
                                                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                                        src={generateThumbnailsUrl(user?.imageUrl.split('/').pop(), 240, loggedInUser.custId, user.userId, spacesConfig)}
                                                                        alt="User"
                                                                        onError={() => setImageError(true)}
                                                                    /> :



                                                                        <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                                                                            <span className="text-sm font-medium leading-none text-white">{getInitials(`${user.peerName}`)}</span>
                                                                        </span>
                                                                    }
                                                                    {/* Offline status */}
                                                                    <span className={`absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-gray-500 ring-2 ring-white`} />
                                                                </span>
                                                                <div className='w-4/6'>
                                                                    <h1 className='font-sans text-gray-900 font-semibold xl:text-lg lg:text-[80%] capitalize truncate w-full' title={user.peerName} >{user.peerName}</h1>
                                                                    <p className='font-sans text-gray-500 self-stretch xl:text-base lg:text-[80%] md:text-[80%] sm:text-[80%]'>{user.peerType}</p>
                                                                </div>
                                                            </div>
                                                            <p className='text-gray-500 xl:text-sm lg:text-[80%] md:text-[80%] sm:text-[80%] max-w-20'>
                                                                <TimeAgo timestamp={user.timestamp} />
                                                            </p>
                                                        </div>
                                                        <p className='w-[95%] font-sans text-sm tracking-wide break-words truncate'>{transformMessage(user.latestMessage)}</p>
                                                    </div>
                                                ))
                                        }



                                    </>

                            }

                        </div> :

                            <div className={`flex flex-col overflow-x-hidden justify-start items-center w-full ${styles.height} ${isChatPopupOpened ? "h-screen" : "overflow-y-scroll scrollbar"}  `} >

                                <ChatDetails active={active} setActive={setActive} setShowDetails={setShowDetails} userDetails={userDetails} isChatPopupOpened={isChatPopupOpened} popupWindow={popupWindow}/>




                            </div>}
                    </>
            }





        </div>
    )
}
