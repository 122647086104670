
import { useEffect } from 'react'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import MaintenanceImage from '../../assets/images/maintenance.svg'
import Button from '../../components/Button'
import { collabWebUrl } from '../../utils/constant'
import { useNavigate } from 'react-router-dom'
import routes from '../../constants/routes'

export default function Maintenance():ReturnType<React.FC> {

    const navigate = useNavigate()
    const isLoggedIn = useAppSelector((state:RootState) => state.login.isLoggedIn)

    useEffect(() => {
        if(isLoggedIn){
            navigate(routes.dashboard)
        }
    },[isLoggedIn, navigate])


    const handleClick = () => {
        window.location.reload();
    }

  return (
    <>

    <div className=" w-screen h-screen bg-gray-50 flex flex-col justify-center items-center gap-2.5 relative">
        <img  src={MaintenanceImage} alt="MaintenanceImage" />
        <h2 className='text-2xl font-bold mt-5'>Temporarily down for maintenance</h2>
        <p className='text-5xl font-bold text-blue'>We will be back soon!</p>
        <p className='w-1/3 text-center '>Sorry for the inconvenience but we’re performing some maintenance at the moment. Please refresh after few seconds.</p>
        <Button children="Home" type='primary' onClick={handleClick} />
        <a href={collabWebUrl} target='_blank' rel='noreferrer' className=' text-blue hover:text-blue text-xl font-bold underline absolute bottom-[20px] ' >CollaborationRoom.AI</a>
    </div>
    
    </>
  )
}
