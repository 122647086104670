const routes = {
  autologin: "/autologin",
  sso: "/sso",
  login: "/login",
  dashboard: "/dashboard",
  faceRegistration: "/faceregistration",
  adminverification: "/adminverification",
  room: "/rm",
  chat: "/chat",
  alerts: "/alerts",
  participants: "/participants",
  passwordReset: "/passwordreset/:resetPasswordToken",
  passwordChange: "/passwordchange",
  personalization: "/personalization",
  whiteboard: "/whiteboard",
  launchApp: "/launchApp",
  training: "/training",
  maintenance:"/maintenance"
};

export default routes;
