import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IncrementPayload, TimerState } from "../../interfaces/slicesInterface/headPose";

const initialState: TimerState = {
  headPose: "",
  headDownCount: 0,
  headUpCount: 0,
};

const headPoseCountSlice = createSlice({
  name: "headPoseCount",
  initialState,
  reducers: {
    incrementDownCount: (state, action: PayloadAction<IncrementPayload>) => {
      state.headDownCount += 1;
      state.headPose = action.payload.headPose;
      if (state.headUpCount >= action.payload.headPoseSeconds) {
        state.headUpCount = state.headDownCount >= action.payload.headPoseSeconds ? 0 : state.headUpCount;
      }
    },
    incrementUpCount: (state, action: PayloadAction<IncrementPayload>) => {
      state.headUpCount += 1;
      state.headPose = action.payload.headPose;
      if (state.headDownCount >= action.payload.headPoseSeconds) {
        state.headDownCount = state.headUpCount >= action.payload.headPoseSeconds ? 0 : state.headDownCount;
      }
    },
    resetCounts: (state) => {
      state.headDownCount = initialState.headDownCount;
      state.headUpCount = initialState.headUpCount;
      state.headPose = initialState.headPose;
    },
  },
});

export const { incrementDownCount, incrementUpCount, resetCounts } = headPoseCountSlice.actions;
export default headPoseCountSlice.reducer;
