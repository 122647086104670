import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Media } from "../../interfaces/componentsInterface/mediaInterface";
import { emojiEventConst, socketConnectiontype } from "../../utils/constant";
import SocketService from "../../services/socket";


const initialState: Media = {
  socketId: "",
  presenter: "",
  remotePeers: {},
  remotePeersId: [],
  instructorPeersId: [],
  currentPage: 1,
  startIndexForRemotePeer: 0,
  endIndexForRemotePeer: 9,
  totalPage: 1,
  localVideoPaused: true,
  localAudioPaused: true,
  localScreenStopped: true,
  localScreenStoppedTwo: true,
  selfPresenter: false,
  isScreenAudioShared: false,
  videoAccessible:true,
  isVideoProducerCreated:false,
  isAudioProducerCreated:false,
  videoPauseAlertInterval: undefined,
  fireAlertForAgenCam: true,
  isCamOffAlertFired: false,
};

const mediaslice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setSocketId: (state, action: PayloadAction<any>) => {
      state.socketId = action.payload;
    },
    setVideoPauseAlertInterval: (state, action: PayloadAction<any>) => {
      state.videoPauseAlertInterval = action.payload;
    },
    clearVideoPauseAlertInterval: (state) => {
      if (state.videoPauseAlertInterval) {
        clearTimeout(state.videoPauseAlertInterval); // Clear the timeout
      }
      state.videoPauseAlertInterval = undefined;
    },
    addEndIndexForRemotePeer: (state, action: PayloadAction<any>) => {
      state.endIndexForRemotePeer = action.payload;
    },
    addRemotePeer: (state, action: PayloadAction<any>) => {
      state.remotePeers[action.payload.remotePeer.peerUserId] =
        action.payload.remotePeer;

      if (!state.remotePeersId.includes(action.payload.remotePeer.peerUserId)) {
        state.remotePeersId = [
          ...state.remotePeersId,
          action.payload.remotePeer.peerUserId,
        ];
      }
      state.totalPage = Math.ceil(
        state.remotePeersId.length / action.payload.perPagePeerNum
      );
    },
    setVideoAccessibleforPeer : (state,action:PayloadAction<any>) => {
        state.videoAccessible = action.payload;
    },
    setRemotePeersAllId: (state, action: PayloadAction<any>) => {
      state.remotePeersId = action.payload;
    },
    editRemotePeer: (state, action: PayloadAction<any>) => {
      if (state.remotePeers[action.payload.peerUserId]) {
        state.remotePeers[action.payload.peerUserId] =
          action.payload.remoteUserData;
      }
    },
    updateRemotePeer: (state, action: PayloadAction<any>) => {
      if (state.remotePeers[action.payload.peerUserId]) {
        state.remotePeers[action.payload.peerUserId] ={...state.remotePeers[action.payload.peerUserId],...action.payload.remoteUserData};
      }
    },
    removeRemotePeer: (state, action: PayloadAction<any>) => {
      let tempObjRemotePeers = { ...state.remotePeers };
      delete tempObjRemotePeers[action.payload.peerUserId];
      state.remotePeers = tempObjRemotePeers;
      state.remotePeersId = state.remotePeersId.filter(
        (item) => item !== action.payload.peerUserId
      );
      state.totalPage = Math.ceil(
        state.remotePeersId.length / action.payload.perPagePeerNum
      );
    },
    nextPage: (state, action: PayloadAction<any>) => {
      if (state.currentPage !== state.totalPage) {
        state.currentPage = state.currentPage + 1;
        state.endIndexForRemotePeer =
          state.currentPage * action.payload.perPagePeerNum;
        state.startIndexForRemotePeer =
          state.endIndexForRemotePeer - action.payload.perPagePeerNum;
      }
    },
    prevPage: (state, action: PayloadAction<any>) => {
      if (state.currentPage >= 1) {
        state.currentPage = state.currentPage - 1;
        state.endIndexForRemotePeer =
          state.currentPage * action.payload.perPagePeerNum;
        state.startIndexForRemotePeer =
          state.endIndexForRemotePeer - action.payload.perPagePeerNum;
      }
    },
    setPage: (state, action: PayloadAction<any>) => {
      state.currentPage = 1;
      state.endIndexForRemotePeer = action.payload.perPagePeerNum;
      state.startIndexForRemotePeer = 0;
      state.totalPage = Math.ceil(
        state.remotePeersId.length / action.payload.perPagePeerNum
      );
    },
    setStartAndEndIndex: (state, action: PayloadAction<any>) => {
      state.endIndexForRemotePeer =
        state.currentPage * action.payload.perPagePeerNum;
      state.startIndexForRemotePeer =
        state.endIndexForRemotePeer - action.payload.perPagePeerNum;
      state.totalPage = Math.ceil(
        state.remotePeersId.length / action.payload.perPagePeerNum
      );
    },

    videoTurnOrOff: (state, action: PayloadAction<any>) => {
      state.localVideoPaused = action.payload;
    },
    screenTurnOrOff: (state, action: PayloadAction<any>) => {
      state.localScreenStopped = action.payload;
    },
    screenTwoTurnOrOff: (state, action: PayloadAction<any>) => {
      state.localScreenStoppedTwo = action.payload;
    },
    setIsScreenAudioShared: (state, action: PayloadAction<any>) => {
      state.isScreenAudioShared = action.payload;
    },
    audioTurnOrOff: (state, action: PayloadAction<any>) => {
      state.localAudioPaused = action.payload;
    },
    setOrUnsetPresenter: (state, action: PayloadAction<any>) => {
      state.presenter = action.payload;
    },
    setSelfPresenter: (state) => {
      state.selfPresenter = !state.selfPresenter;
    },
    setSelfPresenterToInitialState: (state) => {
      state.selfPresenter = initialState.selfPresenter;
    },
    setInstructorIds: (state, action: PayloadAction<any>) => {
      state.instructorPeersId = action.payload;
    },
    insertInstructorId: (state, action: PayloadAction<any>) => {
      if (!state.instructorPeersId.includes(action.payload)) {
        state.instructorPeersId = [...state.instructorPeersId, action.payload];
      }
    },
    removeInstructorId: (state, action: PayloadAction<any>) => {
      if (state.instructorPeersId.includes(action.payload)) {
        state.instructorPeersId = state.instructorPeersId.filter(
          (id) => id !== action.payload
        );
      }
    },
    setIsVideoProducerCreated:(state, action: PayloadAction<any>) => {
    state.isVideoProducerCreated= action.payload;
    },
    setIsAudioProducerCreated:(state, action: PayloadAction<any>) => {
     state.isAudioProducerCreated = action.payload;
    },
    // updateRemotePeers:(state, action: PayloadAction<any>) => {
    //   const index = state.remotePeersId.findIndex((val) => val === action.payload);
    //   state.remotePeersId.splice(index,1);
    // state.remotePeersId.unshift(action.payload);
    // },
    updateOnlineUsersInMedia: (state, action) => {
      const { userId,onlineUsersInChats  }  = action.payload;

     
      const handRaisedUserIds = onlineUsersInChats.filter((user:any) => user.peerUserId === userId).map((user:any) => user.peerUserId);

        const handRaisedUsers = state.remotePeersId.filter((user:any) => handRaisedUserIds.includes(user));
        const otherUsers = state.remotePeersId.filter((user:any) => !handRaisedUserIds.includes(user));
  
        // console.log({userId,onlineUsersInChats,handRaisedUserIds,handRaisedUsers,otherUsers})
        state.remotePeersId = [...handRaisedUsers, ...otherUsers];
    },
    disconnnectSocketMedia:()=>{
      const mediaSocketInstance = new SocketService(
        socketConnectiontype.media
      ).getInstance(socketConnectiontype.media);
      mediaSocketInstance.closeSocketConnection();
    },
    setFireAlertForCam: (state) => {
      state.fireAlertForAgenCam = false;
    },
    setIsCamOffAlertFired: (state, action) => {
      state.isCamOffAlertFired = action.payload;
    },
    clearMediaReducer: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const mediaSelector = (state: RootState) => state.media;
export const {
  addRemotePeer,
  editRemotePeer,
  setSocketId,
  removeRemotePeer,
  addEndIndexForRemotePeer,
  nextPage,
  prevPage,
  videoTurnOrOff,
  audioTurnOrOff,
  screenTurnOrOff,
  screenTwoTurnOrOff,
  setPage,
  setOrUnsetPresenter,
  setInstructorIds,
  insertInstructorId,
  removeInstructorId,
  setStartAndEndIndex,
  clearMediaReducer,
  setSelfPresenter,
  setSelfPresenterToInitialState,
  setIsScreenAudioShared,
  setRemotePeersAllId,
  updateOnlineUsersInMedia,
  updateRemotePeer,
  disconnnectSocketMedia,
  setVideoAccessibleforPeer,
  setIsVideoProducerCreated,
  setIsAudioProducerCreated,
  setVideoPauseAlertInterval,
  clearVideoPauseAlertInterval,
  setFireAlertForCam,
  setIsCamOffAlertFired
} = mediaslice.actions;
export default mediaslice.reducer;
