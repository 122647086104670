import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "../features/login/loginSlice";
import deviceReducer from "../features/device/deviceSlice";
import chatReducer from "../features/chat/chatSlice";
import headPoseReducer from "../features/headPose/headPoseSlice";

import userDetailsReducer from "../features/userDetails/userDetailsSlice";
import allSupervisorReducer from "../features/allSupervisor/allSupervisorSlice";
import environmentReducer from "../features/environment/environmentSlice";
import faceRegistrationReducer from "../features/faceRegistration/faceRegistration";
import adminVerificationReducer from "../features/adminVerification/adminVerification";
import personalizationReducer from "../features/personalization/personalization";
import roomReducer from "../features/room/roomSlice";
import activityChannelReducer from "../features/activityChannel/activityChannelSlice";
import mediaReducer from "../features/media/mediaSlice";
import meetingRoomReducer from "../features/meetingRoom/meetingRoomSlice";
import privateCallReducer from "../features/privateCall/privateCallSlice";
import desktopApp from "../features/desktopApp/desktopAppSlice";
import observeReducer from "../features/observe/observeSlice";
import peerVolumeReducer from "../features/peerVolume/peerVolumeSlice";
import localFileStreamSlice from "../features/localFileStream/localFileStreamSlice";
import tempRoomUser from "../features/tempRoomUser/tempRoomUserSlice";
import scaleResolutionReducer from "../features/scaleResolution/scaleResolutionSlice";
import reconnectionStatusReducer from "../features/reconnectionStatus/reconnectionStatusSlice";
import verifyFaceSlice from "../features/verifyFace/verifyFaceSlice";
import videoBackground from "../features/videoBackground/videoBackgroundSlice";
import videoOptionReducer from "../features/videoOption/videoOptionSlice";
import screenControlReducer from "../features/screenControl/screenControlSlice";
import alerts from "../features/alerts/alertsSlice";
import troubleshootReducer from "../features/troubleshoot/troubleshoot";


const appReducer = combineReducers({
  login: loginReducer,
  device: deviceReducer,
  chat: chatReducer,
  user: userDetailsReducer,
  environment: environmentReducer,
  faceRegistration: faceRegistrationReducer,
  adminVerification: adminVerificationReducer,
  personalization: personalizationReducer,
  room: roomReducer,
  media: mediaReducer,
  activityChannel: activityChannelReducer,
  meetingRoom: meetingRoomReducer,
  privateCall: privateCallReducer,
  desktopApp: desktopApp,
  observe: observeReducer,
  alerts: alerts,
  peerVolume: peerVolumeReducer,
  localfilestreamstatus: localFileStreamSlice,
  scaleResolution: scaleResolutionReducer,
  verifyFaceSlice: verifyFaceSlice,
  videoBackground: videoBackground,
  videoOption: videoOptionReducer,
  tempRoomUser: tempRoomUser,
  reconnectionStatus: reconnectionStatusReducer,
  allSupervisor: allSupervisorReducer,
  screenControl: screenControlReducer,
  troubleshoot: troubleshootReducer,
  headPoseCount: headPoseReducer,
})

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
