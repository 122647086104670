export const alertValues:any = {
  BRB: "Be Right Back",
  Lunch: "Lunch",
  Meeting: "Meeting",
  DND: "DND",
  Break: "Break",
  FaceDetection: "Face Detection",
  CellphoneDetection: "Cellphone Detection",
  EmotionDetection: "Emotion Detection",
  RoomEnter:"Joined the Room",
  RoomLeave:"Left the Room",
  MissedCall:"Missed Call",
  HandRaised:"Hand Raised",
  TechnicalIssue:"Support"
};
