import config from "../config";

const {
  sampleRate = 96000,
  channelCount = 1,
  // volume = 1.0,
  sampleSize = 16,
} = config.centralAudioOptions;

const { frameRate = 15, screenSharingFrameRate = 15 } = config;

const autoGainControl = false; // default : false
const echoCancellation = true; // default : true
const noiseSuppression = true; // default : true

export const getUserDevicesInfo = async () => {
  return await navigator.mediaDevices.enumerateDevices();
};

export const checkVideoDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  let videoDeviceFound = false;
  for (const deviceInfo of devices) {
    if (deviceInfo.kind === "videoinput") {
      videoDeviceFound = true;
      break;
    }
  }
  return videoDeviceFound;
};

export const getAudioPermission = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    stream.getAudioTracks().forEach(function (track) {
      track.stop();
    });
  } catch (err: any) {
    console.error(err);
    const message = "Starting audio device failed.";
    console.error(message);
    throw new Error(err.message);
  }
};

export const getVideoPermission = async () => {
  try {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    stream.getVideoTracks().forEach(function (track) {
      track.stop();
    });
  } catch (err: any) {
    console.error(err);
    const message = "Starting video device failed.";
    console.error(message);
    throw new Error(err.message);
  }
};

export const getAudioStream = async (
  deviceId?: string
): Promise<MediaStream> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: deviceId
        ? {
            deviceId,
            sampleRate,
            channelCount,
            autoGainControl,
            echoCancellation,
            noiseSuppression,
            sampleSize,
          }
        : true,
    });
    return stream;
  } catch (err: any) {
    console.error(err);
    const message = "Starting audio device failed.";
    console.error(message);
    throw new Error(err.message);
  }
};
export const getVideoStream = async (
  deviceId?: string
): Promise<MediaStream> => {
  try {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: deviceId
        ? {
            deviceId: deviceId,
            width: {
              ideal: 1280,
            },
            height: {
              ideal: 720,
            },
            frameRate,
          }
        : true,
    });
    return stream;
  } catch (err: any) {
    console.error(err);
    const message = "Starting video device failed.";
    console.error(message);
    throw new Error(err.message);
  }
};

export const getScreenStream = async (
  videoWidth: any,
  videoHeight: any,
): Promise<MediaStream> => {
  let captureStream = null;
  const mediaDevices = navigator.mediaDevices as any;
  try {
      captureStream = await mediaDevices.getDisplayMedia({
        video: {
          width: videoWidth,
          height: videoHeight,
          frameRate: screenSharingFrameRate,
        },
        audio: true,
      });
  } catch (err: any) {
    console.error("Error: " + err);
    throw new Error(err.message);
  }
  return captureStream;
};

export const playBeepForLunchBrbExtend = () => {
  const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
  const context = new AudioContext();
  const oscillator = context.createOscillator();
  oscillator.type = 'sine';
  oscillator.frequency.setValueAtTime(440, context.currentTime);
  oscillator.connect(context.destination);
  oscillator.start();
  oscillator.stop(context.currentTime + 2);
}

export const playChatNotification = () => {
  const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
  const context = new AudioContext();
  const duration = 0.2; // Duration in seconds


  const channels = 1;
  const frameCount = context.sampleRate * duration;
  const audioBuffer = context.createBuffer(channels, frameCount, context.sampleRate);
  const data = audioBuffer.getChannelData(0);


  for (let i = 0; i < frameCount; i++) {
    const t = i / context.sampleRate;
    data[i] = 0.3 * Math.exp(-15 * (t - 0.1) * (t - 0.1)) * Math.sin(2 * Math.PI * 400 * t);
  }

  const source = context.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(context.destination);

  source.start();
  source.stop(context.currentTime + duration);
}

export const playMentionsNotification = () => {
  const AudioContext: typeof window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
  const context: AudioContext = new AudioContext();

  // Create an oscillator
  const oscillator: OscillatorNode = context.createOscillator();
  oscillator.type = 'triangle'; // Triangle wave for a softer sound
  oscillator.frequency.setValueAtTime(1000, context.currentTime); // Higher pitch

  // Create a gain node (to control the volume)
  const gainNode: GainNode = context.createGain();

  // Set the initial volume of the gain node to 0
  gainNode.gain.setValueAtTime(0, context.currentTime);

  // Gradually increase the volume of the gain node to create a fade-in effect
  gainNode.gain.linearRampToValueAtTime(1, context.currentTime + 0.01);

  // Gradually decrease the volume of the gain node to create a fade-out effect
  gainNode.gain.exponentialRampToValueAtTime(0.001, context.currentTime + 1);

  // Connect the oscillator to the gain node
  oscillator.connect(gainNode);

  // Connect the gain node to the destination (speakers)
  gainNode.connect(context.destination);

  // Start the oscillator
  oscillator.start(context.currentTime);

  // Stop the oscillator after a short time
  oscillator.stop(context.currentTime + 1);
};

