import { AlertsState, EventType } from "../interfaces/slicesInterface/alerts";

export const shouldAddEvent = (
    alerts: AlertsState["alerts"],
    userId: string,
    event: EventType,
    newMessage: string
  ): boolean => {
    const lastEventKey = Object.keys(alerts).find((key) =>
      key.startsWith(`${userId}_${event}`)
    );
  
    if (lastEventKey) {
      const lastEvent = alerts[lastEventKey];
      console.log(lastEventKey);
      return lastEvent.message !== newMessage;
    }
  
    return true;
};