import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from "react";
import ChatList from "../ChatList";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "../../app/store";
import ActivityChannel from "../ActivityChannel";
import {
  ChatProps,
  IConnectionDetails,
} from "../../interfaces/componentsInterface/chats";


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { activityChannelSelector, removeDeletedFeed, setActivityPopup, setFeeds, setModalShowUrgentPost, setSelectedFeedTopic, setSelectedFeedTopicId, setUnreadPostsCount, updateFeeds } from "../../features/activityChannel/activityChannelSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { Subscription } from "rxjs";
import SocketService from "../../services/socket";
import { socketConnectiontype, socketPathName } from "../../utils/constant";
import { getConnectionDetailsForChat } from '../../services/connectionDetails';
import { chatSelector, clearUnseenId, resetChatListing, resetOnlineUsers, resetTypingState, seenMessage, setChatReconnectionStatus, setMessageToList, setNewMessageToList, setOnlineUsers, setPopupOpenedFrom, softDeleteMessage, softEditMessage, updateTypingStatus } from "../../features/chat/chatSlice";
import { fetchChatListing } from "../../services/chat.service";
import { roomSelector } from "../../features/room/roomSlice";
import { playChatNotification, playMentionsNotification } from "../../utils/AudioVideoDevices";
import toast from "react-hot-toast";
import { notifyMe } from "../../utils/desktopNotification";
import { getAllFeeds, getAllTopicsExtended, getConnectionDetailsForActivityChannel, getSubscribedTopics } from "../../services/activitychannel";
import { Role } from "../../interfaces/slicesInterface/activityChannel";
import { truncateMessage } from "../../utils/truncateMessage";
// import { setOnlineUsers, setMessageToList } from "../../features/chat/chatSlice";
// import toast from "react-hot-toast";

// Utility function to concatenate and filter CSS classes
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
const socketInstanceAC = new SocketService(socketConnectiontype.activityChannel).getInstance(
  socketConnectiontype.activityChannel
);

const socketInstanceChat = new SocketService(
  socketConnectiontype.chat
).getInstance(socketConnectiontype.chat);

// Main functional component for Chats
export default function Chats({ active, setActive }: ChatProps): ReturnType<React.FC> {
  const dispatch = useAppDispatch();
  const { activityPopup } = useAppSelector(activityChannelSelector);

  // State to manage the active tab
  const [current, setCurrent] = useState(true);
  const [isChatPopupOpened, setsIsChatPopupOpened] = useState(false);
  const [isACPopupOpened, setsIsACPopupOpened] = useState(false);
  let chatPopup = useRef<Window | null>(null);
  let ACPopup = useRef<Window | null>(null);
  const loggedInUser = useAppSelector(userDetailsSelector);
  const messageSubscriberChatRef = useRef<Subscription | null>(null);
  const roomDetails = useAppSelector(roomSelector);
  let messageSubscriberACRef = useRef<Subscription | null>(null);

  // Tabs configuration
  const tabs = [
    { id: 1, name: "Chats", current: current },
    { id: 2, name: "Feeds", current: !current },
  ];



  // Handle tab change event
  const handleTabChange = (id: number) => {
    id === 1 ? setCurrent(true) : setCurrent(false);
  };


  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.action === "closePopup") {
        if (ACPopup.current) {
          ACPopup.current.close();
          setsIsACPopupOpened(false);
          dispatch(setActivityPopup(false))
        }
      }

      if (event.data?.action === "closeChatPopup") {
        if (chatPopup.current) {
          chatPopup.current.close();
          setsIsChatPopupOpened(false);
          dispatch(setPopupOpenedFrom(""));
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  // Function to handle opening a popout window for the Chats component
  const openChatsPopup = () => {
    let compiledTailwindCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/tailwind/tailwind.output.css`;
    if (!isChatPopupOpened) {
      setsIsChatPopupOpened(true);

      localStorage.setItem("chatPopOut", "true");
      // chatPopup.current = window.open("", "_blank", "width=500,height=650");

      // Calculate the top and left positions based on the current screen's available width and height
      const topPosition = window.screenY + (window.innerHeight * 0.09); // 21% from the top, adjusted for secondary screens
      const leftPosition = window.screenX + (window.innerWidth * 0); // 0% from the left, adjusted for secondary screens

      // Open popup with responsive width, height, and calculated top/left positions
      chatPopup.current = window.open("", "_blank", `width=500,height=650,top=${topPosition},left=${leftPosition}`);

      if (!chatPopup.current) {
        console.error("Failed to open chat popup window.");
        return;
      }

      chatPopup.current.document.open("text/html", "replace");

      chatPopup.current.document.write(`
              <!DOCTYPE html>
              <html lang="en">
              <head>
                <title>Chats - Collaborationroom.ai</title>
                <link rel="stylesheet"  href="${compiledTailwindCssUrl}">
                <style>
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          scroll-behavior: smooth;
          /* overflow: hidden; */
        }

        ::selection {
          background-color: #4EA3DB;
          color: #fff;
        }
        .scrollbar::-webkit-scrollbar {
            width: 10px;
          }

          .scrollbar::-webkit-scrollbar-thumb {
            background-color: #eaeaea;
            border-radius: 10px;
          }

          .scrollbar::-webkit-scrollbar-thumb:hover {
            background: #ccc;
          }

          textarea::placeholder {
            // text-align: center;
            padding: auto 0 !important;
            display: table-cell;
            vertical-align: middle !important;
            margin-top: 10% !important;
          }
            textarea{
  padding-left: 0.4rem;
}

          textarea:focus{

            outline: none;
            max-height: 100%;
            max-width: 100% !important;
            overflow-y: scroll !important;
            overflow-x: hidden;

          }
          .w-full__suggestions{
            margin: 0 !important;
            z-index: 1000;
            margin-left: 2px;
            top: 10% !important;
            left: 10% !important;
          }
          .w-full__suggestions__list{
            z-index: 10;
            background: white;
            border: solid 1px #4EA3DB;
            background: #4EA3DB;
            color: #fff;
          }

.false__suggestions__list{
  max-height: 45px !important;
  overflow-y: scroll !important;
}

.false__suggestions ::-webkit-scrollbar {
  width: 7px;
}

.false__suggestions ::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

.false__suggestions ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}


.w-full__highlighter{
    height: auto !important;
  }
          textarea::-webkit-scrollbar {
            width: 10px;
          }

          textarea::-webkit-scrollbar-thumb {
            background-color: #eaeaea;
            border-radius: 10px;
          }
          .mention {
             color: blue; 
          }

          textarea::-webkit-scrollbar-thumb:hover {
            background: #ccc;
          }

        :root {
          --blue: #4EA3DB;
        }
        a {
         color: blue;
        }
      </style>
                      <script>
                    window.addEventListener('storage', (event) => {
                        if (event.key === 'chatPopOut') {
                            const ischatPopOut = JSON.parse(event.newValue); // Parse the new value from storage
                            if (!ischatPopOut) {
                                window.close();
                            }
                        }
                    });

                    window.addEventListener('beforeunload', () => {
                        localStorage.removeItem("chatPopOut");
                        window.close()
                        window.opener.postMessage('chatPopupClosed', '*');
                        if (window.opener) {
                          window.opener.postMessage({ action: "closeChatPopup" }, "*");
                        }
                    });
                </script>
              </head>
              <body>
                <div id="root"></div>
              </body>
              </html>
            `);

      chatPopup.current.document.close();
      // chatPopup.current.onresize = function() {
      //     chatPopup.current && chatPopup.current.resizeTo(chatPopup.current.screenX * .01, chatPopup.current.screenY * .01);
      //   };

      chatPopup.current.onload = () => {


        var reloading = sessionStorage.getItem("reloading");
        if (reloading) {
        console.log("INSIDEEEE")
          sessionStorage.removeItem("reloading");

        }
        const rootElement = chatPopup.current?.document.getElementById("root");

        if (rootElement) {
          const root = createRoot(rootElement);
          root.render(
            <Provider store={store}>
              <ChatList
                active={active}
                setActive={setActive}
                isChatPopupOpened={true}
                fromPopup={true}
                popupWindow={chatPopup.current}
              />
            </Provider>
          );

          console.log(chatPopup.current)
        } else {
          console.error("Root element not found in the chat popup window.");
        }
      };

      const closePopupChat = () => {
        if (chatPopup.current) {
          chatPopup.current.close();
          setsIsChatPopupOpened(false)
        }
      };

      // Add event listener for beforeunload to close the popup on page reload or navigation
      window.addEventListener("beforeunload", closePopupChat);


      chatPopup.current.onbeforeunload = () => {
        console.log("Event")
        setsIsChatPopupOpened(false);
        if (chatPopup.current) {
          // console.log("EventHURRR", chatPopup.current)
          if (chatPopup.current.document.title === '') {
            // chatPopup.current.document.close();
            chatPopup.current.close()
          }


        }
      };


      function reloadP() {
        sessionStorage.setItem("reloading", "true");
        document.location.reload();
      }



      window.addEventListener('message', (event) => {
        if (event.data === 'chatPopupClosed') {
          // Perform the desired action here
          console.log("Chat popup was closed");
          setsIsChatPopupOpened(false);

        }
      });
    }
  };
  // Function to handle opening a popout window for the Chats component
  const openFeedsPopup = () => {
    let compiledTailwindCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/tailwind/tailwind.output.css`;
     let compiledEditorCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/react-draft-editor/react-draft-wysiwyg.css`
    if (!isACPopupOpened && !activityPopup) {
      setsIsACPopupOpened(true);
      dispatch(setActivityPopup(true))

      localStorage.setItem("ACPopOut", "true");
      // ACPopup.current = window.open("", "_blank", "width=500,height=650");

      // Calculate the top and left positions based on the current screen's available width and height
      const topPosition = window.screenY + (window.innerHeight * 0.15); // Adjust for multi-screen positioning
      const leftPosition = window.screenX + (window.innerWidth * 0.05); // Adjust for multi-screen positioning

      // Open popup with responsive width, height, and calculated top/left positions
      ACPopup.current = window.open("", "_blank", `width=500,height=650,top=${topPosition},left=${leftPosition}`);

      if (!ACPopup.current) {
        console.error("Failed to open feed popup window.");
        return;
      }

      ACPopup.current.document.open("text/html", "replace");

      ACPopup.current.document.write(`
      <!DOCTYPE html>
        <html lang="en">
        <head>
            <title>ActivityChannel - Collaborationroom.ai</title>
            <link rel="stylesheet" href="${compiledTailwindCssUrl}">
             <link rel="stylesheet" href="${compiledEditorCssUrl}">

            <style>
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    scroll-behavior: smooth;
                }

                ::selection {
                    background-color: #4EA3DB;
                    color: #fff;
                }

                .scrollbar::-webkit-scrollbar {
                    width: 10px;
                }

                .scrollbar::-webkit-scrollbar-thumb {
                    background-color: #eaeaea;
                    border-radius: 10px;
                }

                .scrollbar::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }
                .rdw-emoji-modal {
                  width: 7.5rem !important; /* Reduce the modal width */
                  height: 5rem !important; /* Reduce the modal height */
                }
                .rdw-emoji-modal .rdw-emoji-icon {
                  width: 17px; /* Adjust emoji size */
                  height: 17px;
                  font-size: 17px;
                }

                textarea::placeholder {
                    text-align: center;
                    padding: auto 0 !important;
                    display: table-cell;
                    vertical-align: middle !important;
                    margin-top: 10% !important;
                }

                p {
                    overflow-wrap: anywhere;
                    font-size: 0.75rem;
                }

                a {
                    color: blue;
                    text-decoration: inherit;
                    overflow-wrap: break-word;
                }

                textarea:focus {
                    outline: none;
                    max-height: 100%;
                    max-width: 100% !important;
                    overflow-y: scroll !important;
                    overflow-x: hidden;
                }

                .w-full__suggestions {
                    margin: 0 !important;
                    z-index: 1000;
                    margin-left: 2px;
                    top: 10% !important;
                    left: 10% !important;
                }

                .w-full__suggestions__list {
                    z-index: 10;
                    background: white;
                    border: solid 1px #4EA3DB;
                    background: #4EA3DB;
                    color: #fff;
                }

                .w-full__highlighter {
                    height: auto !important;
                }

                textarea::-webkit-scrollbar {
                    width: 10px;
                }

                textarea::-webkit-scrollbar-thumb {
                    background-color: #eaeaea;
                    border-radius: 10px;
                }

                textarea::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }

                :root {
                    --blue: #4EA3DB;
                }

                .activityscrollmenu {
                    height: 10rem !important;
                }

                #modal-headline {
                    color: #FF0000;
                }

                h3 {
                    word-break: break-all !important;
                }


                 .text_editor {
                    width: 100%;
                    margin-top: 10px;
                    border: solid 1.5px #dadada;
                    border-radius: 5px;
                    padding: 5px;
                }

                .text_editor__controls {
                    margin-bottom: 10px;
                }

                .text_editor__controls button {
                    margin-right: 10px;
                }

               .text_editor__textarea.bold {
                    font-weight: bold;
                }

                .text_editor__textarea.italic {
                    font-style: italic;
                }

                .rdw-editor-main{
                    word-break: break-all;
                }
            </style>
            <script>
                window.addEventListener('storage', (event) => {
                    if (event.key === 'ACPopOut') {
                        const isACPopOut = JSON.parse(event.newValue); // Parse the new value from storage
                        if (!isACPopOut) {
                            window.close();
                        }
                    }
                });

                window.addEventListener('beforeunload', () => {
                    if (window.opener) {
                        window.opener.postMessage({ action: "closePopup" }, "*");
                    }
                });
            </script>
        </head>
        <body>
            <div id="root"></div>
        </body>
        </html>
    `);


      ACPopup.current.document.close();

      ACPopup.current.onload = () => {
        const rootElement = ACPopup.current?.document.getElementById("root");

        if (rootElement) {
          const root = createRoot(rootElement);
          root.render(
            <Provider store={store}>
              <ActivityChannel
                active={active}
                setActive={setActive}
                isACPopupOpened={true}
                fromACPopup={true}
                popupWindow={ACPopup.current}
              />
            </Provider>
          );
        } else {
          console.error("Root element not found in the feed popup window.");
        }
      };

      const closePopup = () => {
        if (ACPopup.current) {
          ACPopup.current.close();
          setsIsACPopupOpened(false);
          dispatch(setActivityPopup(false))
        }
      };

      // Add event listener for beforeunload to close the popup on page reload or navigation
      window.addEventListener("beforeunload", closePopup);

      // Ensure the event listener is removed when the component is unmounted or popup is closed
      ACPopup.current.onbeforeunload = () => {
        setsIsACPopupOpened(false);
        dispatch(setActivityPopup(false))
        window.removeEventListener("beforeunload", closePopup);
        // localStorage.setItem("ACPopOut", "false");
      };
    }
  };

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     if (ACPopup.current) {
  //       ACPopup.current.close();
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const queryDataForChatList = {
    loggedInUserId: loggedInUser.userId,
    clientId: loggedInUser.custId,
    roomId: roomDetails.currentRoomId,
  };

  // Set up socket connection and message subscription
  useEffect(() => {
    if (!chatPopup.current) {
      fetchConnectionDetails();

      // messageSubscriberChatRef.current = socketInstanceChat
      //   .getMessages()
      //   .subscribe((message: string) => {
      //     processMessageForChat(JSON.parse(message));
      //   });

      socketInstanceChat.messageSubscriber = socketInstanceChat
        .getMessages()
        .subscribe((message: string) => {
          processMessageForChat(JSON.parse(message));
        });
    }

    if (!ACPopup.current) {
      fetchConnectionDetailsForAC();


      socketInstanceAC.messageSubscriber = socketInstanceAC.getMessages().subscribe((message: string) => {
        processMessageFromACservice(JSON.parse(message));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // messageSubscriberACRef.current = socketInstanceAC.getMessages().subscribe((message: string) => {
      //   processMessageFromACservice(JSON.parse(message));
      // });
    }

    // }


    return () => {
      // Unsubscribe in the cleanup function
      // const disconnectSockets = async () => {
        // if (socketInstanceChat) {
        //   await socketInstanceChat.sendMessage(
        //     "MANUAL_DISCONNECT_PEER",
        //     {}
        //   );
        // }


        // if (messageSubscriberChatRef.current) {
        //   messageSubscriberChatRef.current.unsubscribe();
        //   messageSubscriberChatRef.current = null;
        // }

        // if (socketInstanceChat?.messageSubscriber) {
        //   socketInstanceChat.messageSubscriber.unsubscribe();
        //   socketInstanceChat.messageSubscriber = null;
        // }

        // if (socketInstanceChat) {
        //   socketInstanceChat.closeSocketConnection(true);
        //   socketInstanceChat.reconnectFlushState();
        //   socketInstanceChat.socket = undefined;
        //   dispatch(resetOnlineUsers());
        //   dispatch(resetChatListing());
        //   // closeChatsPopup();
        // }

        // if (messageSubscriberACRef.current) {
        //   messageSubscriberACRef.current.unsubscribe();
        //   messageSubscriberACRef.current = null;
        // }
        // if (socketInstanceAC) {
        //   socketInstanceAC.closeSocketConnection(true);
        //   socketInstanceAC.reconnectFlushState();
        //   socketInstanceAC.socket = undefined;
        // }

      // }

      // disconnectSockets();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch connection details for chat
  const fetchConnectionDetails = async () => {

    let hostnameChat = process.env.REACT_APP_CHAT_WS_URL!;

    if (!hostnameChat) {
      if (window.location.origin.includes("localhost")) {
        hostnameChat = "http://localhost:8084";
      } else {
        hostnameChat = window.location.origin; // Default to the current origin
      }
    }
    // if (process.env.REACT_APP_SOCKET_SERVER_URL)
    //   hostnameChat = process.env.REACT_APP_SOCKET_SERVER_URL;


    const connectionDetailsChat: IConnectionDetails =
      await getConnectionDetailsForChat(
        roomDetails.currentRoom,
        roomDetails.currentRoomId
      );
    socketInstanceChat.connectToSocket(
      hostnameChat,
      connectionDetailsChat.path,
      connectionDetailsChat.payload,
      connectionDetailsChat.iv,
      connectionDetailsChat.digest,
      roomDetails.viewMode,
      `${socketPathName.chat}`
    );

  };


  const getOnlineChatUsers = async () => {
    return await socketInstanceChat.sendMessage(
      "GET_ONLINE_CHAT_USERS",
      {}
    );
  };

  // Process incoming messages for chat
  const processMessageForChat = async (message: any) => {
    switch (message.type) {
      case "SELF_CONNECTION_ACKNOWLEDGE":
        console.log(message.selfSocketId + ": " + message.message);
        break;
      case "CHAT_SOCKET_CONNECTED":
        getOnlineChatUsers().then((response: any) => {
          dispatch(setOnlineUsers(response.users));

          // Necessary. Because we want to assign socketId to Past user that just came online
          dispatch(fetchChatListing(queryDataForChatList));
        });
        break;
      case "CHAT_SOCKET_DISCONNECTED":
        console.log("Chat socket Disconnected for user: ", message.user);
        break;
      case "CHAT_LEAVE_NOTIFY":
        console.log(message);
        getOnlineChatUsers().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });
        break;
      case "NEW_CHAT_MESSAGE":
        if (/^(https?:\/\/[^\s]+)$/.test(message.message) && message.message.includes("whiteboard")) {
          window.open(message.message, "_blank");
        }
        let mentionsMessage=false;

        if (message) {
          let shouldNotifyAudio = false;
          let shouldNotifyToast = false;
          let shouldNotifyDesktop = false;
          const fullName = `@ ${loggedInUser.firstName} ${loggedInUser.lastName}‎`.toLowerCase();
          const messageContent = message.message.toLowerCase();
            if (messageContent.includes(fullName) && store.getState().personalization.personalization.settings?.mentions) {
              shouldNotifyAudio = true; // Flag to play audio notification
              shouldNotifyToast = true; // Flag to show toast notification
              shouldNotifyDesktop = true; // Flag to show desktop notification
              mentionsMessage = true;
            }

          // Play audio notification if needed
          if (shouldNotifyAudio && store.getState().personalization.personalization.settings?.mentions?.audio) {
            playMentionsNotification();
            shouldNotifyAudio = false; // Reset the flag
          }
          // Show toast notification if needed
          if (shouldNotifyToast && store.getState().personalization.personalization.settings?.mentions?.toast) {
            // const toastId = message.chatType === 'PRIVATE' ? "3" : "4";
            const toastMessage = `${message.sender.senderUsername} mentioned you in ${message.chatType === 'PRIVATE' ? 'Private Chat' : 'Room'}`;
            toast.dismiss();
            toast.success(toastMessage);
            shouldNotifyToast = false; // Reset the flag
          }

          // Show desktop notification if needed
          if (shouldNotifyDesktop && store.getState().personalization.personalization.settings?.mentions?.desktop) {
            const notificationMessage = `${message.sender.senderUsername} mentioned you in ${message.chatType === 'PRIVATE' ? 'Private Chat' : 'Room'}`;
            notifyMe(notificationMessage);
            shouldNotifyDesktop = false; // Reset the flag
          }
        }



        if (message && message?.sender.senderUserId !== loggedInUser.userId && store.getState().personalization.personalization?.settings && !mentionsMessage) {

          if (store.getState().personalization.personalization?.settings?.chat?.audio && message.chatType !== "OTHERROOM") {
            playChatNotification();
          }

          if (store.getState().personalization.personalization?.settings?.chat?.toast) {
            console.log("JDS1010:")
            toast.dismiss();
            message.chatType === 'PRIVATE' ?  toast.success(`New Message From ${message.sender?.senderUsername}`) : toast.success(`New Message in ${message.room.roomName} from ${message.sender?.senderUsername}`)
          }

          if (store.getState().personalization.personalization?.settings?.chat?.desktop) {
            message.chatType === 'PRIVATE' ? notifyMe(`New Message From ${message.sender.senderUsername}`) : message.chatType==="SAMEROOM" && notifyMe(`New Message in ${message.room.roomName} from ${message.sender.senderUsername}`)
          }
        }
        dispatch(setNewMessageToList(message))
        if(message.chatType==="PRIVATE"){
          dispatch(clearUnseenId())
        }
        if(store.getState()?.chat?.selectedChatUser?.chatType===message.chatType){
        dispatch(setMessageToList(message));
        }
        dispatch(fetchChatListing(queryDataForChatList));
        break;
      case "EDIT_MESSAGE":
        // if (chatDetails.messageList.length) {
        dispatch(
          softEditMessage({
            _id: message._id,
            message: message.editedMessage,
          })
        );
        // }
        break;
      case "DELETE_MESSAGE":
        // if (chatDetails.messageList.length) {
        dispatch(softDeleteMessage(message));
        // }
        dispatch(fetchChatListing(queryDataForChatList));
        break;
      case "TYPING":
        let typingData = {
          active: true,
          typerName: message.sender.peerName,
          typerUserId: message.sender.userId,
          typerSocketId: message.sender.senderSocketId,
          chatType: message.chatType,
        };
        dispatch(updateTypingStatus(typingData));
        break;
      case "STOP_TYPING":
        dispatch(resetTypingState());
        break;
      case "UPDATE_SEEN_STATUS":
        // let dataToSend = {
        //   senderSocketId: "",
        //   senderUserId: message.senderUserId,
        //   receiverUserId: message.receiverUserId,
        //   receiverSocketId: chatDetails.selectedChatUser.peerSessionId,
        // };
        // socketInstanceChat.sendMessage(
        //   "UPDATE_SEEN_STATUS_DB",
        //   dataToSend
        // );
      let dataToSend = {
        senderSocketId: "",
        senderUserId: message.receiverUserId,
        receiverUserId: message.senderUserId,
        receiverSocketId: store.getState().chat.selectedChatUser.peerSessionId,
      };
      socketInstanceChat.sendMessage(
        "UPDATE_SEEN_STATUS_DB",
        dataToSend
      );
        let partnerUser = {
          userId: message.senderUserId,
        };
        dispatch(seenMessage({ partnerUser }));
        break;
      default:
        break;
    }
  };

  // Check if user has SPECIFIC permission for an TOPIC (permissionToCheck can be 'read', 'write' or 'none')
  const hasPermission = (
    topicId: string,
    currentRole: string,
    permissionToCheck: string
  ): boolean => {
    // Find the topic with the given ID
    const topic = store
      .getState()
      .activityChannel.subscribedTopics.find((topic: any) => topic._id === topicId);
    console.log("TOPIC", topic);
    // If found, check if the currentRole has SPECIFIC permission
    if (topic) {
      const rolePermissions = topic.permissions[currentRole as Role];
      console.log({ rolePermissions });
      console.log(rolePermissions.includes(permissionToCheck.toLowerCase()));
      return rolePermissions.includes(permissionToCheck.toLowerCase());
    }
    //  Return false if topic not found or read SPECIFIC not present
    return false;
  };

  const showNewPostNotification = (username: string) => {
    // Play audio notification if needed
    if (store.getState().personalization?.personalization?.settings?.activity?.audio) {
      playChatNotification();
    }

    // Show toast notification if needed
    if (store.getState().personalization?.personalization?.settings?.activity?.toast) {
      // const toastId: string = "5";
      const toastMessage = `New Post in Activity Channel from ${username}`;
      toast.dismiss(); // close any existing toast to prevent toast queue
      toast.success(`${toastMessage}`);
    }

    // Show desktop notification if needed
    if (store.getState().personalization?.personalization?.settings?.activity?.desktop) {
      const notificationMessage = `New Post in Activity Channel from ${username}`;
      notifyMe(notificationMessage);
    }
  };

  const getFeeds = async (topicIds: string[], pageNo: Number, fetchMyPostsOnly: boolean) => {
    if (topicIds.length > 0) {
      dispatch(getAllFeeds({ topicIds, pageNo, myPosts: fetchMyPostsOnly }));
    }
  };

  const processMessageFromACservice = async (message: any) => {
    console.log({ message });
    switch (message.type) {
      case "CHANNEL_SOCKET_CONNECTED":
        console.log("Successfully connected to socket through redis");
        break;

      case "CHANNEL_SOCKET_DISCONNECTED":
        console.log("DISCONNECTED FROM SOCKET SERVER");
        break;

      case "NEW_POST_ACTIVITY_CHANNEL":
        const feed = message.feed;

        if (
          store.getState().activityChannel.selectedFeedTopic === "topics" &&
          store.getState().activityChannel.subscribedTopicIds.includes(feed.topicId) &&
          hasPermission(feed.topicId, loggedInUser.role, "read")
        ) {
          dispatch(
            setUnreadPostsCount(store.getState().activityChannel.unreadPostsCount + 1)
          );
        }
        if (
          (store.getState().activityChannel.selectedFeedTopic === "allPosts" &&
            store
              .getState()
              .activityChannel.subscribedTopicIds.includes(feed.topicId)) ||
          store.getState().activityChannel.selectedFeedTopicId === feed.topicId
        ) {
          // getFeeds(store.getState().activityChannel.subscribedTopicIds, 1, false);
          dispatch(setFeeds(feed));
          if (feed.userId !== loggedInUser.userId) {
            // Don't show notifications to self
            showNewPostNotification(`${feed.username ? feed.username : `${feed.firstName + " " + feed.lastName}`}`);
          }
        }

        if (store.getState().activityChannel.selectedFeedTopic === "myPosts" && loggedInUser.userId === feed.userId) {
          dispatch(setFeeds(feed));
          if (feed.userId !== loggedInUser.userId) {
            // Don't show notifications to self
            showNewPostNotification(`${feed.username ? feed.username : `${feed.firstName + " " + feed.lastName}`}`);
          }
        }
        if (
          feed.type === "urgent" &&
          feed.userId !== loggedInUser.userId &&
          store.getState().activityChannel.subscribedTopicIds.includes(feed.topicId) &&
          hasPermission(feed.topicId, loggedInUser.role, "read")
        ) {
          dispatch(setModalShowUrgentPost(true));
        }
        break;
      case "UPDATE_FROM_ADMIN":
        if (message.msg === "FEED_DELETED") {
          dispatch(removeDeletedFeed(message.id));
        } else {
          dispatch(getAllTopicsExtended());
          dispatch(getSubscribedTopics());
          // setTopicSelected(null);
          // dispatch(setPageNo(1))

          if (message.id === store.getState().activityChannel.selectedFeedTopicId) {
            // setSelectedFeedTopic("allPosts");
            // setSelectedFeedTopicId("");
            dispatch(setSelectedFeedTopic("allPosts"));
            dispatch(setSelectedFeedTopicId(""));
          } else {
            if (
              store.getState().activityChannel.selectedFeedTopic === "allPosts" ||
              store.getState().activityChannel.selectedFeedTopic === "myPosts"
            ) {
              if (store.getState().activityChannel.selectedFeedTopic === "myPosts") {
                // getFeeds(
                //   store.getState().activityChannel.subscribedTopicIds,
                //   1,
                //   true
                // );
                dispatch(updateFeeds(message?.updatedFeed))
              } else {
                // getFeeds(
                //   store.getState().activityChannel.subscribedTopicIds,
                //   1,
                //   false
                // );
                dispatch(updateFeeds(message?.updatedFeed))
              }
            } else {
              // getFeeds([store.getState().activityChannel.selectedFeedTopicId], 1, false);
              dispatch(updateFeeds(message?.updatedFeed))
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const fetchConnectionDetailsForAC = async () => {
    const connectionDetails = await getConnectionDetailsForActivityChannel(loggedInUser.username);


    let hostnameAC = process.env.REACT_APP_AC_WS_URL!;

    if (!hostnameAC) {
      if (window.location.origin.includes("localhost")) {
        hostnameAC = "http://localhost:8080";
      } else {
        hostnameAC = window.location.origin; // Default to the current origin
      }
    }

    console.log(
      "Connection details in dashboard",
      hostnameAC,
      connectionDetails.path,
      connectionDetails.payload,
      connectionDetails.iv,
      connectionDetails.digest
    );

    console.log(socketInstanceAC);

    socketInstanceAC.connectToSocket(
      hostnameAC,
      connectionDetails.path,
      connectionDetails.payload,
      connectionDetails.iv,
      connectionDetails.digest,
      "",
      socketPathName.activityChannel
    );
  };

  // Render the component
  return (
    <>
      <div
        className={`w-full ${active ? " h-2/4" : "h-full"
          } bg-white rounded-2xl sm:w-full transition-all  duration-100 shadow-md gap-1 overflow-x-hidden`}
      >
        <div
          className={`flex flex-col justify-center items-center p-2 lg:gap-1 xl:gap-4 ${active ? "h-[30%]" : "h-[15%]"
            } `}
        >
          <div className=" w-full flex justify-between items-center">
            <h1
              title="Amplify Your Experience with Chats and Feeds"
              className=" font-sans text-gray-900 text-base font-semibold"
            >
              Chats & Feeds
            </h1>
          </div>
          <div className="w-full p-2 bg-gray-50 rounded-2xl sm:mt-5 lg:mt-0 xl:mt-0">
            <div className="">
              <nav className="isolate flex  rounded-lg " aria-label="Tabs">
                {tabs.map((tab, tabIdx) => (
                  <p
                    key={tab.name}
                    className={classNames(
                      tab.current
                        ? "text-gray-900 bg-white rounded-lg shadow-md"
                        : "text-gray-500 bg-gray-50",
                      "relative min-w-0 flex-1 overflow-hidden xl:py-4 xl:px-4 lg:py-2 lg:px-2 sm:py-2 text-center xl:text-base lg:text-sm sm:text-base font-medium cursor-pointer "
                    )}
                    aria-current={tab.current ? "page" : undefined}
                    onClick={() => handleTabChange(tab.id)}
                  >
                    <span className="flex justify-center items-center gap-2">
                      {tab.name}{" "}
                      {/* <div className=" bg-blue-50 text-blue p-2  rounded-[100%] flex justify-center items-center"></div> */}

                      {
                        tab.name === 'Chats' ? <ArrowTopRightOnSquareIcon
                          className={`xl:h-5 xl:w-5 ${!tab.current && "text-gray-300"}  lg:h-4 lg:w-4 sm:h-4 sm-h-4  text-base ${isChatPopupOpened ? 'text-blue drop-shadow-sm hover:text-blue' : 'text-gray-500 hover:text-gray-900'}   hover:cursor-pointer transition-colors demo `}
                          onClick={openChatsPopup}
                          title="Popout Chat"
                        />
                          : (
                            <ArrowTopRightOnSquareIcon
                              className={`xl:h-5 xl:w-5  lg:h-4 lg:w-4 sm:h-4 sm-h-4 ${!tab.current && "text-gray-300"
                                }  text-base ${isACPopupOpened
                                  ? "text-blue drop-shadow-sm hover:text-blue"
                                  : "text-gray-500 hover:text-gray-900"
                                }   hover:cursor-pointer transition-colors demo `}
                              onClick={openFeedsPopup}
                              title="Popout Feed"
                            />
                          )}
                    </span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </p>
                ))}
              </nav>
            </div>
          </div>
        </div>
        {current ? (
          <ChatList
            active={active}
            setActive={setActive}
            isChatPopupOpened={isChatPopupOpened}
            fromPopup={false}
          />
        ) : (
          <ActivityChannel
            active={active}
            setActive={setActive}
            isACPopupOpened={isACPopupOpened || activityPopup}
            fromACPopup={false}
          />
        )}
      </div>
    </>
  );
}
