import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Button from "../Button";
import { useAppSelector } from "../../app/hooks";
import { emojiEventConst, socketConnectiontype } from "../../utils/constant";
import SocketService from "../../services/socket";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { getFormattedMinutes } from "../../utils/datetimeformat";
import { ControlActionModalWithoutTimeLimitProps } from "../../interfaces/componentsInterface/controlActionModalWithoutTimeLimit";

export default function ControlActionModalWithoutTimeLimit(props: ControlActionModalWithoutTimeLimitProps): ReturnType<React.FC> {

  const [time, setTime] = useState("0 Min");
  const userState = useAppSelector(userDetailsSelector);

  let messageSubscriberChat: any;

  const socketInstanceChat = new SocketService(
    socketConnectiontype.chat
  ).getInstance(socketConnectiontype.chat);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriberChat = socketInstanceChat
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });
    return () => {
      messageSubscriberChat.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processMessage = async (message: any) => {
    switch (message.type) {
      case "EMOJI_FOR_MEETING":
        if (userState.userId === message.peerUserId) {
          //@ts-ignore
          setTime(getFormattedMinutes(message.time, 2, ' Hour '));
        }
        break;
      default:
        break;
    }
  }

  const handleModalClose = () => {
    setTime("0 Min");
  };

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden backdrop-blur-sm">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[40%] sm:max-w-[50%] max-h-[70vh] overflow-y-scroll scrollbar sm:p-6">
                  <p
                    className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                    title="Close"
                    onClick={() => {
                      props.closeModalNoLimit?.();
                      handleModalClose();
                    }}
                  >
                    <XMarkIcon className=" h-5 w-5" />
                  </p>
                  <Dialog.Title
                    as="h3"
                    className="xl:text-lg lg:text-base text-center font-bold leading-6 text-gray-900"
                  >
                    {props.selectedEvent === emojiEventConst.MEETING ? (
                      <>On Meeting</>
                    ) : null}
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center gap-2">
                    <p className=" text-5xl text-blue border-[.5px] border-blue px-3 py-1 rounded-md" >{time}</p>
                    <Button children="Go Back" type="primary" className="py-[1px]" onClick={() => {
                      props.closeModalNoLimit?.();
                      handleModalClose();
                    }} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
