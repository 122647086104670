import React, { useState, useEffect, ChangeEvent, KeyboardEvent, useRef } from 'react';



interface MentionProps {
  value: string;
  onChange: (value: string) => void;
  suggestions: string[];
  checkEnterKey:any;
  handleInputBlur:any;
  typingStatusInput:any;
}

const MAX_LINES = 3;
const LINE_HEIGHT = 26; // Adjust based on your font-size and line-height

const MentionInput: React.FC<MentionProps> = ({ value, onChange, suggestions,checkEnterKey,handleInputBlur ,typingStatusInput}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  // const [selectionIndex, setSelectionIndex] = useState(-1);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [currentLineCount, setCurrentLineCount] = useState(1)

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
    typingStatusInput()

    if(newValue==='' && currentLineCount>1){
      setCurrentLineCount(1)
    }

    // Show suggestions if '@' is present
    if (newValue.includes('@')) {
      const lastAtIndex = newValue.lastIndexOf('@');
      const query = newValue.slice(lastAtIndex + 1);
      const filtered = suggestions.filter(s => s.toLowerCase().includes(query.toLowerCase()));
      setFilteredSuggestions(filtered);
      setShowSuggestions(filtered.length > 0);
    } else {
      setShowSuggestions(false);
    }

    // Scroll to the bottom of the textarea
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };

  // const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  //   if (e.key === 'ArrowDown') {
  //     setSelectionIndex((prevIndex) => Math.min(prevIndex + 1, filteredSuggestions.length - 1));
  //   } else if (e.key === 'ArrowUp') {
  //     setSelectionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  //   } else if (e.key === 'Enter' && selectionIndex >= 0) {
  //     e.preventDefault();
  //     const lastAtIndex = value.lastIndexOf('@');
  //     // Add a space after '@' symbol before inserting the suggestion
  //     const newValue = `${value.slice(0, lastAtIndex + 1)} ${filteredSuggestions[selectionIndex]} `;
  //     onChange(newValue);
  //     setShowSuggestions(false);
  //     setSelectionIndex(-1);
  //   }
  // };
  
  const handleSuggestionClick = (suggestion: string) => {
    const lastAtIndex = value.lastIndexOf('@');
    // Add a space after '@' symbol before inserting the suggestion
    const newValue = `${value.slice(0, lastAtIndex + 1)} ${suggestion}‎ `;
    // const newValue = `${value.slice(0, lastAtIndex + 1)} ${suggestion}⠀`;
    onChange(newValue);
    setShowSuggestions(false);
  };
  

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      if (value.includes("\n")) {
        if (value.split("\n").length > currentLineCount) {
          setCurrentLineCount(value.split("\n").length);
        }
      } 
      else {
        setCurrentLineCount(value.length / 21 <= 2 ? Math.floor(value.length / 21 + 1) : 2);
      }
    }
    if(value==='' && currentLineCount>1){
      setCurrentLineCount(1)
    }
  }, [value]);

  return (
    <div className="relative w-2/3 flex justify-end">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        onKeyDown={checkEnterKey}
        onBlur={handleInputBlur}
        placeholder="Write..."
        className={`w-full rounded-md border-2 border-gray-300 flex-wrap focus:outline-none leading-5 transition ease-in-out sm:text-sm  resize-none overflow-hidden  max-h-[72px] scrollbar overflow-y-auto
          '}`}
        style={{ maxHeight: currentLineCount <= MAX_LINES ? `${LINE_HEIGHT * currentLineCount}px` : '72px' }}
      />
      {showSuggestions && (
        <div className={`absolute text-sm bottom-full z-10 bg-white border border-gray-300 rounded-md shadow-md mb-1 w-full max-w-[100%] max-h-20 scrollbar overflow-y-auto`}>
          {filteredSuggestions.length > 0 ? (
            filteredSuggestions.map((suggestion, index) => (
              <div
                key={suggestion}
                className={`p-1 cursor-pointer truncate w-full`}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No suggestions</div>
          )}
        </div>
      )}
    </div>
  );
};

export default MentionInput;